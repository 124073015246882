export const AppRoutes = {
  auth: {
    self: 'auth',
    login: 'login',
    register: {
      self: 'register',
      start: 'start',
      verify: 'verify',
      finish: 'finish',
    },
    recovery: {
      self: 'recovery',
      start: 'start',
      verify: 'verify',
      finish: 'finish',
    },
  },
  main: {
    // Main
    self: '',
    dashboard: 'dashboard',
    dashboardEmpty: 'empty',

    // Orders
    orders: {
      self: 'orders',
      list: {
        self: 'list',
        unfinished: 'unfinished',
        inProcesses: 'in-processes',
        completed: 'completed',
        canceled: 'canceled',
        carts: 'carts',
      },
      add: 'add',
      edit: 'edit',
    },

    // Management
    stores: {
      self: 'stores',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    points: {
      self: 'points',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    suppliers: {
      self: 'suppliers',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },

    // Handbooks
    products: {
      self: 'products',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    categories: {
      self: 'categories',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    properties: {
      self: 'properties',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    manufacturers: {
      self: 'manufacturers',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    badges: {
      self: 'badges',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    units: {
      self: 'units',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    stockStatuses: {
      self: 'stock-statuses',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    currencies: {
      self: 'currencies',
      list: 'list',
      add: 'add',
      edit: 'edit',
      currencyRates: 'currency-rates',
    },
    countries: {
      self: 'countries',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    regions: {
      self: 'regions',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    cities: {
      self: 'cities',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },

    // Users
    users: {
      self: 'users',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    employees: {
      self: 'employees',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },

    // Positions
    roles: {
      self: 'roles',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    permissions: {
      self: 'permissions',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },

    // Account
    account: {
      self: 'account',
      profile: 'https://tozon.tj/account/dashboard',
      editProfile: 'edit-profile',
      addresses: {
        self: 'addresses',
        list: 'list',
        add: 'add',
        edit: ':itemId',
      },
      changePassword: 'https://tozon.tj/account/change-password',
    },

    // Settings
    slides: {
      self: 'slides',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    banners: {
      self: 'banners',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    editorPages: {
      self: 'editor-pages',
      shipping: 'shipping',
      payment: 'payment',
      shippingAndPayment: 'shipping-and-payment',
      news: 'news',
      masters: 'masters',
      pickPoints: 'pick-points',
      partnership: 'partnership',
      aboutUs: 'about-us',
      contactUs: 'contact-us',
      mobileApp: 'mobile-app',
      faq: 'faq',
      terms: 'terms',
    },
    languages: {
      self: 'languages',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },
    externalLinks: {
      self: 'external-links',
      list: 'list',
      add: 'add',
      edit: 'edit',
    },

    // Pages
    notFound: '**',
    accessDenied: 'access-denied',
    internalServerError: 'internal-server-error',
  },
};

export const AppRoutesFull = {
  auth: {
    self: `${AppRoutes.auth.self}`,
    login: `${AppRoutes.auth.self}/${AppRoutes.auth.login}`,
    register: {
      self: `${AppRoutes.auth.self}/${AppRoutes.auth.register.self}`,
      start: `${AppRoutes.auth.self}/${AppRoutes.auth.register.self}/${AppRoutes.auth.register.start}`,
      verify: `${AppRoutes.auth.self}/${AppRoutes.auth.register.self}/${AppRoutes.auth.register.verify}`,
      finish: `${AppRoutes.auth.self}/${AppRoutes.auth.register.self}/${AppRoutes.auth.register.finish}`,
    },
    recovery: {
      self: `${AppRoutes.auth.self}/${AppRoutes.auth.recovery.self}`,
      start: `${AppRoutes.auth.self}/${AppRoutes.auth.recovery.self}/${AppRoutes.auth.recovery.start}`,
      verify: `${AppRoutes.auth.self}/${AppRoutes.auth.recovery.self}/${AppRoutes.auth.recovery.verify}`,
      finish: `${AppRoutes.auth.self}/${AppRoutes.auth.recovery.self}/${AppRoutes.auth.recovery.finish}`,
    }
    ,
  },
  main: {
    // Main
    self: `${AppRoutes.main.self}`,
    dashboard: `${AppRoutes.main.dashboard}`,
    dashboardEmpty: `${AppRoutes.main.dashboard}/${AppRoutes.main.dashboardEmpty}`,

    // Orders
    orders: {
      self: `${AppRoutes.main.orders.self}`,
      list: {
        self: `${AppRoutes.main.orders.self}/${AppRoutes.main.orders.list.self}`,
        unfinished: `${AppRoutes.main.orders.self}/${AppRoutes.main.orders.list.self}/${AppRoutes.main.orders.list.unfinished}`,
        inProcesses: `${AppRoutes.main.orders.self}/${AppRoutes.main.orders.list.self}/${AppRoutes.main.orders.list.inProcesses}`,
        completed: `${AppRoutes.main.orders.self}/${AppRoutes.main.orders.list.self}/${AppRoutes.main.orders.list.completed}`,
        canceled: `${AppRoutes.main.orders.self}/${AppRoutes.main.orders.list.self}/${AppRoutes.main.orders.list.canceled}`,
        carts: `${AppRoutes.main.orders.self}/${AppRoutes.main.orders.list.self}/${AppRoutes.main.orders.list.carts}`,
      },
      add: `${AppRoutes.main.orders.self}/${AppRoutes.main.orders.add}`,
      edit: `${AppRoutes.main.orders.self}/${AppRoutes.main.orders.edit}`,
    },

    // Management
    stores: {
      self: `${AppRoutes.main.stores.self}`,
      list: `${AppRoutes.main.stores.self}/${AppRoutes.main.stores.list}`,
      add: `${AppRoutes.main.stores.self}/${AppRoutes.main.stores.add}`,
      edit: `${AppRoutes.main.stores.self}/${AppRoutes.main.stores.edit}`,
    },
    points: {
      self: `${AppRoutes.main.points.self}`,
      list: `${AppRoutes.main.points.self}/${AppRoutes.main.points.list}`,
      add: `${AppRoutes.main.points.self}/${AppRoutes.main.points.add}`,
      edit: `${AppRoutes.main.points.self}/${AppRoutes.main.points.edit}`,
    },
    suppliers: {
      self: `${AppRoutes.main.suppliers.self}`,
      list: `${AppRoutes.main.suppliers.self}/${AppRoutes.main.suppliers.list}`,
      add: `${AppRoutes.main.suppliers.self}/${AppRoutes.main.suppliers.add}`,
      edit: `${AppRoutes.main.suppliers.self}/${AppRoutes.main.suppliers.edit}`,
    },

    // Handbooks
    products: {
      self: `${AppRoutes.main.products.self}`,
      list: `${AppRoutes.main.products.self}/${AppRoutes.main.products.list}`,
      add: `${AppRoutes.main.products.self}/${AppRoutes.main.products.add}`,
      edit: `${AppRoutes.main.products.self}/${AppRoutes.main.products.edit}`,
    },
    categories: {
      self: `${AppRoutes.main.categories.self}`,
      list: `${AppRoutes.main.categories.self}/${AppRoutes.main.categories.list}`,
      add: `${AppRoutes.main.categories.self}/${AppRoutes.main.categories.add}`,
      edit: `${AppRoutes.main.categories.self}/${AppRoutes.main.categories.edit}`,
    },
    properties: {
      self: `${AppRoutes.main.properties.self}`,
      list: `${AppRoutes.main.properties.self}/${AppRoutes.main.properties.list}`,
      add: `${AppRoutes.main.properties.self}/${AppRoutes.main.properties.add}`,
      edit: `${AppRoutes.main.properties.self}/${AppRoutes.main.properties.edit}`,
    },
    manufacturers: {
      self: `${AppRoutes.main.manufacturers.self}`,
      list: `${AppRoutes.main.manufacturers.self}/${AppRoutes.main.manufacturers.list}`,
      add: `${AppRoutes.main.manufacturers.self}/${AppRoutes.main.manufacturers.add}`,
      edit: `${AppRoutes.main.manufacturers.self}/${AppRoutes.main.manufacturers.edit}`,
    },
    badges: {
      self: `${AppRoutes.main.badges.self}`,
      list: `${AppRoutes.main.badges.self}/${AppRoutes.main.badges.list}`,
      add: `${AppRoutes.main.badges.self}/${AppRoutes.main.badges.add}`,
      edit: `${AppRoutes.main.badges.self}/${AppRoutes.main.badges.edit}`,
    },
    units: {
      self: `${AppRoutes.main.units.self}`,
      list: `${AppRoutes.main.units.self}/${AppRoutes.main.units.list}`,
      add: `${AppRoutes.main.units.self}/${AppRoutes.main.units.add}`,
      edit: `${AppRoutes.main.units.self}/${AppRoutes.main.units.edit}`,
    },
    stockStatuses: {
      self: `${AppRoutes.main.stockStatuses.self}`,
      list: `${AppRoutes.main.stockStatuses.self}/${AppRoutes.main.stockStatuses.list}`,
      add: `${AppRoutes.main.stockStatuses.self}/${AppRoutes.main.stockStatuses.add}`,
      edit: `${AppRoutes.main.stockStatuses.self}/${AppRoutes.main.stockStatuses.edit}`,
    },
    currencies: {
      self: `${AppRoutes.main.currencies.self}`,
      list: `${AppRoutes.main.currencies.self}/${AppRoutes.main.currencies.list}`,
      add: `${AppRoutes.main.currencies.self}/${AppRoutes.main.currencies.add}`,
      edit: `${AppRoutes.main.currencies.self}/${AppRoutes.main.currencies.edit}`,
      currencyRates: `${AppRoutes.main.currencies.self}/${AppRoutes.main.currencies.currencyRates}`,
    },
    countries: {
      self: `${AppRoutes.main.countries.self}`,
      list: `${AppRoutes.main.countries.self}/${AppRoutes.main.countries.list}`,
      add: `${AppRoutes.main.countries.self}/${AppRoutes.main.countries.add}`,
      edit: `${AppRoutes.main.countries.self}/${AppRoutes.main.countries.edit}`,
    },
    regions: {
      self: `${AppRoutes.main.regions.self}`,
      list: `${AppRoutes.main.regions.self}/${AppRoutes.main.regions.list}`,
      add: `${AppRoutes.main.regions.self}/${AppRoutes.main.regions.add}`,
      edit: `${AppRoutes.main.regions.self}/${AppRoutes.main.regions.edit}`,
    },
    cities: {
      self: `${AppRoutes.main.cities.self}`,
      list: `${AppRoutes.main.cities.self}/${AppRoutes.main.cities.list}`,
      add: `${AppRoutes.main.cities.self}/${AppRoutes.main.cities.add}`,
      edit: `${AppRoutes.main.cities.self}/${AppRoutes.main.cities.edit}`,
    },

    // Users
    users: {
      self: `${AppRoutes.main.users.self}`,
      list: `${AppRoutes.main.users.self}/${AppRoutes.main.users.list}`,
      add: `${AppRoutes.main.users.self}/${AppRoutes.main.users.add}`,
      edit: `${AppRoutes.main.users.self}/${AppRoutes.main.users.edit}`,
    },
    employees: {
      self: `${AppRoutes.main.employees.self}`,
      list: `${AppRoutes.main.employees.self}/${AppRoutes.main.employees.list}`,
      add: `${AppRoutes.main.employees.self}/${AppRoutes.main.employees.add}`,
      edit: `${AppRoutes.main.employees.self}/${AppRoutes.main.employees.edit}`,
    },

    // Positions
    roles: {
      self: `${AppRoutes.main.roles.self}`,
      list: `${AppRoutes.main.roles.self}/${AppRoutes.main.roles.list}`,
      add: `${AppRoutes.main.roles.self}/${AppRoutes.main.roles.add}`,
      edit: `${AppRoutes.main.roles.self}/${AppRoutes.main.roles.edit}`,
    },
    permissions: {
      self: `${AppRoutes.main.permissions.self}`,
      list: `${AppRoutes.main.permissions.self}/${AppRoutes.main.permissions.list}`,
      add: `${AppRoutes.main.permissions.self}/${AppRoutes.main.permissions.add}`,
      edit: `${AppRoutes.main.permissions.self}/${AppRoutes.main.permissions.edit}`,
    },

    // Account
    account: {
      self: `${AppRoutes.main.account.self}`,
      profile: `${AppRoutes.main.account.profile}`,
      editProfile: `${AppRoutes.main.account.self}/${AppRoutes.main.account.editProfile}`,
      addresses: {
        self: `${AppRoutes.main.account.self}/${AppRoutes.main.account.addresses.self}`,
        list: `${AppRoutes.main.account.self}/${AppRoutes.main.account.addresses.self}/${AppRoutes.main.account.addresses.list}`,
        add: `${AppRoutes.main.account.self}/${AppRoutes.main.account.addresses.self}/${AppRoutes.main.account.addresses.add}`,
        edit: `${AppRoutes.main.account.self}/${AppRoutes.main.account.addresses.self}/${AppRoutes.main.account.addresses.edit}`,
      },
      changePassword: `${AppRoutes.main.account.changePassword}`,
    },

    // Settings
    slides: {
      self: `${AppRoutes.main.slides.self}`,
      list: `${AppRoutes.main.slides.self}/${AppRoutes.main.slides.list}`,
      add: `${AppRoutes.main.slides.self}/${AppRoutes.main.slides.add}`,
      edit: `${AppRoutes.main.slides.self}/${AppRoutes.main.slides.edit}`,
    },
    banners: {
      self: `${AppRoutes.main.banners.self}`,
      list: `${AppRoutes.main.banners.self}/${AppRoutes.main.banners.list}`,
      add: `${AppRoutes.main.banners.self}/${AppRoutes.main.banners.add}`,
      edit: `${AppRoutes.main.banners.self}/${AppRoutes.main.banners.edit}`,
    },
    editorPages: {
      self: `${AppRoutes.main.editorPages.self}`,
      shipping: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.shipping}`,
      payment: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.payment}`,
      shippingAndPayment: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.shippingAndPayment}`,
      news: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.news}`,
      masters: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.masters}`,
      pickPoints: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.pickPoints}`,
      partnership: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.partnership}`,
      aboutUs: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.aboutUs}`,
      contactUs: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.contactUs}`,
      mobileApp: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.mobileApp}`,
      faq: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.faq}`,
      terms: `${AppRoutes.main.editorPages.self}/${AppRoutes.main.editorPages.terms}`,
    },
    languages: {
      self: `${AppRoutes.main.languages.self}`,
      list: `${AppRoutes.main.languages.self}/${AppRoutes.main.languages.list}`,
      add: `${AppRoutes.main.languages.self}/${AppRoutes.main.languages.add}`,
      edit: `${AppRoutes.main.languages.self}/${AppRoutes.main.languages.edit}`,
    },
    externalLinks: {
      self: `${AppRoutes.main.externalLinks.self}`,
      list: `${AppRoutes.main.externalLinks.self}/${AppRoutes.main.externalLinks.list}`,
      add: `${AppRoutes.main.externalLinks.self}/${AppRoutes.main.externalLinks.add}`,
      edit: `${AppRoutes.main.externalLinks.self}/${AppRoutes.main.externalLinks.edit}`,
    },

    // Pages
    notFound: `${AppRoutes.main.notFound}`,
    accessDenied: `${AppRoutes.main.accessDenied}`,
    internalServerError: `${AppRoutes.main.internalServerError}`,
  },
};
