import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
// services
import {BaseService} from './base.service';
// models
import {City, HttpResponseAPI} from '@onlineShop/models';
// functions
import {getQueryParams} from '@onlineShop/functions';
// rxjs
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CityService extends BaseService<City> {
  protected url = 'client/general/info/cities';
  private _url = 'cp/cities';

  constructor(
    protected _http: HttpClient,
  ) {
    super(_http);
  }

  // get cities
  getCities(params: any = {}): Observable<HttpResponseAPI> {
    return this._http.get<HttpResponseAPI>(this._url, getQueryParams(params))
      .pipe(
        map((result: HttpResponseAPI) => {
          result.data = result.data as City[];
          return result;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // update city country
  updateCityActive(cityId: number, active: boolean): Observable<boolean> {
    const body = {active, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this._url}/${cityId}/update-active`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as boolean;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }
}
