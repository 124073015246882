import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
// services
import {AuthService} from '@onlineShop/services/auth.service';
import {ConfigService} from '@onlineShop/services/config.service';
import {CookieService} from 'ngx-cookie-service';
// models
import {CookieToken, Token} from '@onlineShop/models';
// enums
import {Auth} from '@onlineShop/enums';
// rxjs
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, map, switchMap, take} from 'rxjs/operators';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  private _isRefreshing = false;
  private _refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _configService: ConfigService,
    private _cookieService: CookieService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request.url;

    // set base url
    if (!(/^https?/).test(url)) {
      request = request.clone({
        url: `${this._configService.apiUrl}/${url}`
      });
    }

    // check cookie
    // this._checkCookie();

    // set token header if need
    if (this._authService.accessToken && !url.endsWith('login') && !url.includes('storage/images/flags')) {
      request = this._addToken(request);
    }

    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (!this._configService.production) {
              console.log('event--->>>', event);
            }

            if (url.indexOf('translation') !== -1) {
              event = event.clone({
                body: event.body.data.app
              });
            }
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          if (!this._configService.production) {
            console.log('error status: ' + error.status);
            console.log(error.error);
          }
          if (error.status === 0) {
            return next.handle(request);
            // return throwError({message: 'Не удалось подключиться к серверу.', status: error.status});
          } else if (error.status >= 500) {
            return throwError({message: 'Ошибка сервера', status: error.status});
          } else if (error.status === 422) {
            return throwError({...error.error, status: error.status});
          } else if (error.status === 404) {
            return throwError({...error.error, status: error.status});
          } else if (error.status === 403) {
            return throwError({...error.error, status: error.status});
          }

          // We don't want to refresh token for some requests like login or refresh token itself
          // So we verify url and we throw an error if it's the case
          if (url.includes('login') || url.includes('refresh')) {
            // We do another check to see if refresh token failed
            // In this case we want to logout user and to redirect it to login page
            if (url.includes('refresh')) {
              this._authService.logout();
            }
            return throwError(error);
          }

          // If error status is different than 401 we want to skip refresh token
          // So we check that and throw the error if it's the case
          if (error.status !== 401) {
            return throwError(error);
          }

          if (this._isRefreshing) {
            // If _isRefreshing is true, we will wait until _refreshToken$ has a non-null value
            // – which means the new token is ready and we can retry the request again

            return this._refreshToken$
              .pipe(
                filter(result => !!result),
                take(1),
                switchMap(() => next.handle(this._addToken(request))),
              );
          } else {
            this._isRefreshing = true;

            // Set the _refreshToken$ to null so that subsequent API calls will wait until the new token has been retrieved
            this._refreshToken$.next(null);

            // Call auth.refreshAccessToken(this is an Observable that will be returned)
            return this._authService.refreshToken()
              .pipe(
                switchMap((token: Token) => {
                  // When the call to refreshToken completes we reset the _isRefreshing to false
                  // for the next time the token needs to be refreshed
                  this._isRefreshing = false;
                  this._refreshToken$.next(token.refresh_token);

                  return next.handle(this._addToken(request));
                }),
                catchError((err: HttpErrorResponse) => {
                  this._isRefreshing = false;
                  this._authService.logout();
                  return throwError(err);
                })
              );
          }
        })
      );
  }

  private _addToken(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = this._authService.accessToken;
    const tokenType = this._authService.tokenType;

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: `${tokenType} ${accessToken}`
      }
    });
  }

  private _checkCookie(): void {
    const accessToken = this._cookieService.get(Auth.ACCESS_TOKEN);
    const refreshToken = this._cookieService.get(Auth.REFRESH_TOKEN);
    const tokenType = this._cookieService.get(Auth.TOKEN_TYPE);
    const expiresIn = this._cookieService.get(Auth.EXPIRES_IN);
    const remember = this._cookieService.get(Auth.REMEMBER);

    if (accessToken && refreshToken && tokenType && expiresIn && remember) {
      if (this._authService.expiresIn && this._authService.expiresIn < Number(expiresIn)) {
        const cookieToken: CookieToken = {
          token: {
            access_token: accessToken.toString(),
            refresh_token: refreshToken.toString(),
            token_type: tokenType.toString(),
            expires_in: Number(expiresIn),
          },
          remember: Boolean(remember)
        };

        this._authService.setTokenFromCookie(cookieToken);
      }
    }
  }
}
