<form (ngSubmit)="onSubmitClick()" [formGroup]="formGroup" autocomplete="off">
  <h2 mat-dialog-title>{{item.title ? item.title : 'Адрес'}}</h2>
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="space-between none" class="m-t-1">
      <!-- Message Error -->
      <div *ngIf="errorMessage || (error && error.length > 0)" class="m-b-20" fxFlex="100">
        <mat-error class="m-0 p-0 message-error">{{errorMessage}}</mat-error>
        <mat-error *ngFor="let err of error" class="m-0 p-0 message-error font-12">
          <div *ngIf="isArray(err)">
            <mat-error *ngFor="let e of err" class="m-0 p-0 message-error font-12">
              {{e}}
            </mat-error>
          </div>
          <div *ngIf="isArray(err) === false">
            {{err}}
          </div>
        </mat-error>
      </div>

      <!-- Address -->
      <div formGroupName="address" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between none">
        <!-- Address Line 1 -->
        <div fxFlex="100" fxFlex.gt-xs="100">
          <mat-form-field appearance="outline">
            <mat-label>Адресная строка 1</mat-label>
            <input [maxLength]="inpLn.address.addressLine1.maxLength" formControlName="addressLine1"
                   inputMaxLength
                   matInput
                   placeholder="Посёлок/Джамоат/Село/Мкр/Улица"
                   required>
            <mat-error
              *ngIf="formGroup.get('address.addressLine1').hasError('required') &&
                     formGroup.get('address.addressLine1').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Укажите адресную строку 1</span>
            </mat-error>
            <mat-error
              *ngIf="formGroup.get('address.addressLine1').hasError('maxlength') &&
                     formGroup.get('address.addressLine1').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Проверьте правильность адресной строке 1</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Address Line 2 -->
        <div fxFlex="100" fxFlex.gt-xs="100">
          <mat-form-field appearance="outline">
            <mat-label>Адресная строка 2</mat-label>
            <input [maxLength]="inpLn.address.addressLine2.maxLength" formControlName="addressLine2"
                   inputMaxLength
                   matInput
                   placeholder="Улица/Дом/Квартира"
                   required>
            <mat-error
              *ngIf="formGroup.get('address.addressLine2').hasError('required') &&
                     formGroup.get('address.addressLine2').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Укажите адресную строку 2</span>
            </mat-error>
            <mat-error
              *ngIf="formGroup.get('address.addressLine2').hasError('maxlength') &&
                     formGroup.get('address.addressLine2').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Проверьте правильность адресной строке 2</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Country -->
        <div fxFlex="100" fxFlex.gt-xs="48">
          <mat-form-field appearance="outline">
            <mat-label>Страна</mat-label>
            <input *ngIf="!countries || countries.length === 0"
                   [maxLength]="inpLn.address.country.maxLength"
                   formControlName="country"
                   inputMaxLength
                   matInput
                   required>
            <mat-select (openedChange)="setCountries()"
                        (selectionChange)="changeCountry($event)"
                        *ngIf="countries && countries.length > 0"
                        disableOptionCentering
                        formControlName="country"
                        panelClass="mat-select-option-is-bottom"
                        required>
              <mat-option *ngFor="let country of countries" [value]="country">
                <mat-icon [svgIcon]="country.flag"></mat-icon>
                <span>{{country.name}}</span>
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="formGroup.get('address.country').hasError('required') &&
                    formGroup.get('address.country').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Укажите Страну</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Region -->
        <div fxFlex="100" fxFlex.gt-xs="48">
          <mat-form-field appearance="outline">
            <mat-label>Край/Область/Регион</mat-label>
            <input *ngIf="!regions || regions.length === 0"
                   [maxLength]="inpLn.address.region.maxLength"
                   formControlName="region"
                   inputMaxLength
                   matInput
                   required>
            <mat-select (selectionChange)="changeRegion($event)"
                        *ngIf="regions && regions.length > 0"
                        disableOptionCentering
                        formControlName="region"
                        panelClass="mat-select-option-is-bottom"
                        required>
              <mat-option *ngFor="let region of regions" [value]="region">
                {{region.name}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="formGroup.get('address.region').hasError('required') &&
                    formGroup.get('address.region').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Укажите Край/Область/Регион</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- City -->
        <div fxFlex="100" fxFlex.gt-xs="48">
          <mat-form-field appearance="outline">
            <mat-label>Город/Район</mat-label>
            <input *ngIf="!cities || cities.length === 0"
                   [maxLength]="inpLn.address.city.maxLength"
                   formControlName="city"
                   inputMaxLength
                   matInput
                   required>
            <mat-select *ngIf="cities && cities.length > 0"
                        disableOptionCentering
                        formControlName="city"
                        panelClass="mat-select-option-is-bottom"
                        required>
              <mat-option *ngFor="let city of cities" [value]="city">
                {{city.name}}
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="formGroup.get('address.city').hasError('required') &&
                    formGroup.get('address.city').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Укажите Город</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Post Code -->
        <div fxFlex="100" fxFlex.gt-xs="48">
          <mat-form-field appearance="outline">
            <mat-label>Почтовый индекс</mat-label>
            <input [maxLength]="inpLn.address.postCode.maxLength" formControlName="postCode"
                   inputMaxLength
                   autocomplete="new-post-code"
                   matInput>
            <mat-error
              *ngIf="formGroup.get('address.postCode').hasError('required') &&
                     formGroup.get('address.postCode').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Укажите почтовый индекс</span>
            </mat-error>
            <mat-error
              *ngIf="(formGroup.get('address.postCode').hasError('postCodeInValid') ||
                     formGroup.get('address.postCode').hasError('maxlength')) &&
                     formGroup.get('address.postCode').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Укажите правильный почтовый индекс</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Default Address -->
        <div class="check-default-address" fxFlex="100">
          <mat-checkbox color="primary" formControlName="default">Сделать адресом по умолчанию</mat-checkbox>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div [style.box-sizing]="'content-box'" align="end" mat-dialog-actions>
    <input #closeDialog [mat-dialog-close]="addresses" type="hidden">
    <button mat-button mat-dialog-close mat-flat-button type="button">Отменить</button>
    <button mat-button type="submit" color="primary">{{item.textButton}}</button>
  </div>
</form>
