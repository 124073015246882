import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
// services
import {BaseService} from './base.service';
// models
import {
  CreateOrderItemRequest,
  DeleteOrderItemRequest,
  GetOrderItemRequest,
  HttpResponseAPI,
  Order,
  OrderItem,
  OrderStatus,
  SyncItemsInOrderRequest,
  UpdateOrderItemRequest
} from '@onlineShop/models';
// functions
import {getQueryParams} from '@onlineShop/functions';
// rxjs
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends BaseService<Order> {

  protected url = 'cp/orders';
  private _url = 'client/orders';

  constructor(
    protected _http: HttpClient,
  ) {
    super(_http);
  }

  // get order status
  getOrderStatus(status: number): OrderStatus {
    if (status !== null && status !== undefined) {
      const result = this.orderStatuses.filter(s => s.status === status)[0];
      return result ? result : {status, name: status.toString(), color: ''};
    } else {
      return null;
    }
  }

  // order statuses
  get orderStatuses(): OrderStatus[] {
    return [
      {status: 0, name: 'В процессе заказа', color: ''},
      {status: 1, name: 'Завершено', color: '#28a745'},
      {status: 2, name: 'Отправлено', color: '#1e88e5'},
      {status: 3, name: 'Проверяются', color: '#1e88e5'},
      {status: 4, name: 'В ожидании', color: '#ffb22b'},
      {status: -1, name: 'Отменено', color: '#fc4b6c'}
    ];
  }

  // get orders
  getOrders(params: any = {}, status: string = ''): Observable<HttpResponseAPI> {

    if (status) {
      status = `/${status}`;
    } else {
      status = '';
    }

    return this._http.get<HttpResponseAPI>(this.url + status, getQueryParams(params))
      .pipe(
        map((result: HttpResponseAPI) => {
          result.data = result.data as Order[];
          return result;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // get order with items
  getOrderWithItems(orderId: number): Observable<Order> {
    return this._http.get<HttpResponseAPI>(`${this.url}/${orderId}/items`)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as Order;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // sync items in order
  syncItemsInOrder(orderId: string, body: SyncItemsInOrderRequest): Observable<Order> {
    return this._http.post<HttpResponseAPI>(`${this._url}/${orderId}/items/sync`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as Order;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // get item in order
  getItemInOrder(body: GetOrderItemRequest): Observable<OrderItem> {
    return this._http.get<HttpResponseAPI>(`${this.url}/${body.orderId}/items/${body.id}`)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as OrderItem;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // add item in order
  createItemInOrder(body: CreateOrderItemRequest): Observable<Order> {
    return this._http.post<HttpResponseAPI>(`${this.url}/${body.orderId}/items`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as Order;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // add item in order
  updateItemInOrder(body: UpdateOrderItemRequest): Observable<Order> {
    body = {...body, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this.url}/${body.orderId}/items/${body.id}`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as Order;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // delete item in order
  deleteItemInOrder(body: DeleteOrderItemRequest): Observable<Order> {
    body = {...body, _method: 'DELETE'};

    return this._http.post<HttpResponseAPI>(`${this.url}/${body.orderId}/items/${body.id}`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as Order;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // update status order
  updateStatusOrder(orderId: number, status: number): Observable<boolean> {
    const body = {status, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this.url}/${orderId}/update-status`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as boolean;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }
}
