export const Age = {
  min: 16,
  max: 100
};

export const InputLength = {
  verifyCode: {
    length: 6,
  },
  firstName: {
    minLength: 2,
    maxLength: 100,
  },
  dateOfBirth: {
    day: {
      minLength: 1,
      maxLength: 2,
      min: 1,
      max: 31,
    },
    month: {
      minLength: 1,
      maxLength: 2,
      min: 1,
      max: 12,
    },
    year: {
      length: 4,
      min: new Date().getFullYear() - Age.max,
      max: new Date().getFullYear() - Age.min,
    },
  },
  email: {
    maxLength: 254,
  },
  address: {
    addressLine1: {
      maxLength: 254,
    },
    addressLine2: {
      maxLength: 254,
    },
    country: {
      maxLength: 254,
    },
    region: {
      maxLength: 254,
    },
    city: {
      maxLength: 254,
    },
    postCode: {
      maxLength: 254,
    }
  },
  password: {
    minLength: 8,
    maxLength: 32,
  },
  product: {
    name: {
      minLength: 2,
      maxLength: 100,
    },
    slug: {
      minLength: 2,
      maxLength: 254,
    },
    sku: {
      minLength: 2,
      maxLength: 64,
    },
    price: {
      min: 0,
      max: 9999999,
    },
    quantity: {
      min: 0,
      max: 9999999,
    },
    unitStep: {
      min: 0.01,
      max: 9999999,
    },
    weight: {
      min: 0,
      max: 9999999,
    },
    width: {
      min: 0,
      max: 9999999,
    },
    height: {
      min: 0,
      max: 9999999,
    },
    length: {
      min: 0,
      max: 9999999,
    },
    convStep: {
      min: 0.0001,
      max: 9999999,
    },
    metaTitle: {
      minLength: 2,
      maxLength: 254,
    },
    description: {
      minLength: 2,
      maxLength: 10485760,
    },
  },
  propertyGroup: {
    name: {
      minLength: 2,
      maxLength: 100,
    },
    description: {
      minLength: 2,
      maxLength: 100,
    },
  },
  property: {
    name: {
      minLength: 2,
      maxLength: 100,
    },
    slug: {
      minLength: 2,
      maxLength: 254,
    },
    description: {
      minLength: 2,
      maxLength: 100,
    },
  },
  manufacturer: {
    name: {
      minLength: 2,
      maxLength: 100,
    },
  },
  supplier: {
    name: {
      minLength: 2,
      maxLength: 100,
    },
    address: {
      minLength: 5,
      maxLength: 254,
    },
  },
  point: {
    name: {
      minLength: 2,
      maxLength: 100,
    },
    address: {
      minLength: 5,
      maxLength: 254,
    },
  },
  banner: {
    title: {
      minLength: 2,
      maxLength: 100,
    },
    text: {
      minLength: 2,
      maxLength: 254,
    },
    url: {
      minLength: 2,
      maxLength: 254,
    },
    urlText: {
      minLength: 2,
      maxLength: 100,
    },
  },
  slide: {
    title: {
      minLength: 2,
      maxLength: 100,
    },
    text: {
      minLength: 2,
      maxLength: 254,
    },
    url: {
      minLength: 2,
      maxLength: 254,
    },
    urlText: {
      minLength: 2,
      maxLength: 100,
    },
  },
  category: {
    name: {
      minLength: 2,
      maxLength: 100,
    },
    slug: {
      minLength: 2,
      maxLength: 254,
    },
  }
};
