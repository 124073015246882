import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
// angular material
import {MatDialog} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';
// services
import {AuthService} from '@onlineShop/services/auth.service';
import {ConfigService} from '@onlineShop/services/config.service';
import {AccountService} from '@onlineShop/services/account.service';
// components
import {UserModalComponent} from '@onlineShop/shared/components/user-modal/user-modal.component';
// models
import {Role, User} from '@onlineShop/models';
// classes
import {GetRoutes} from '@onlineShop/classes';
// rxjs
import {Observable, of, ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-menu-profile',
  templateUrl: './menu-profile.component.html',
  styleUrls: ['./menu-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuProfileComponent implements OnDestroy {

  // get routes
  gr = new GetRoutes();
  // dark mode
  dark: Observable<boolean> = of(false);
  // user
  user: User;

  isMobileApp = false;

  private _destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private _dialog: MatDialog,
    private _authService: AuthService,
    private _accountService: AccountService,
    private _configService: ConfigService,
  ) {
    this.dark = this._configService.darkMode$;
    this.user = this._accountService.currentUser;
    this.isMobileApp = this._configService.IsMobileApp();
  }

  get userRoles(): Role[] {
    return this._accountService.roles;
  }

  changeMode(event: MatCheckboxChange): void {
    this._configService.setDarkMode(event.checked);
  }

  openModal(user: User): void {
    this._dialog.open(UserModalComponent, {data: {...user, title: 'Мой профиль', type: 'profile'}});
  }

  changeRole(role: string): void {
    this._accountService.setUserRole(role);
  }

  logout(): void {
    this._authService.logoutServer()
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
          this._authService.logout();
        },
        (res: HttpErrorResponse) => {
          this._authService.logout();
        }
      );
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
