import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
// functions
import {getQueryParams, isFunction} from '@onlineShop/functions';
// models
import {HttpResponseAPI} from '@onlineShop/models';
// rxjs
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BaseService<T> {
  protected url: string;
  protected normalize;
  protected normalizeRequest;
  protected sort;

  protected constructor(
    @Inject(HttpClient) protected _http: HttpClient) {
  }

  create(body: T | null | any = null): Observable<T | boolean> {
    if (isFunction(this.normalizeRequest)) {
      body = this.normalizeRequest(body) as T;
    }

    return this._http.post<HttpResponseAPI>(this.url, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          const data = result.data;
          return (isFunction(this.normalize) ? this.normalize(data) : data) as T;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  read(): Observable<T[]> {
    return this._http.get<HttpResponseAPI>(this.url)
      .pipe(
        map((result: HttpResponseAPI) => {
          const data = result.data as T[];
          return this._normalizeResponseData(data || []);
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  readByParams(params): Observable<T[]> {
    return this._http.get<HttpResponseAPI>(`${this.url}/params`, getQueryParams(params))
      .pipe(
        map((result: HttpResponseAPI) => {
          const data = result.data as T[];
          return this._normalizeResponseData(data || []);
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  readById(id: string | number): Observable<T> {
    return this._http.get<HttpResponseAPI>(`${this.url}/${id}`)
      .pipe(
        map((result: HttpResponseAPI) => {
          const data = result.data;
          return (isFunction(this.normalize) ? this.normalize(data) : data) as T;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  update(body: T | any): Observable<T | boolean> {
    if (isFunction(this.normalizeRequest)) {
      body = this.normalizeRequest(body) as T;
    }
    body = {...body, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this.url}/${body.id}`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          const data = result.data || {};
          return (isFunction(this.normalize) ? this.normalize(data) : data) as T | boolean;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  delete(id: string | number): Observable<any> {
    const body = {_method: 'DELETE'};

    return this._http.post<HttpResponseAPI>(`${this.url}/${id}`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          const data = result && result.data ? result.data : true;
          return (isFunction(this.normalize) ? this.normalize(data) : data);
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  protected _normalizeResponseData(data: T[]): T[] {
    if (isFunction(this.normalize)) {
      data = data.map(this.normalize);
    }
    if (isFunction(this.sort)) {
      data.sort(this.sort);
    }
    return data;
  }
}
