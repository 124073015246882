import {Directive, HostListener, Input, OnDestroy} from '@angular/core';
import {NgControl} from '@angular/forms';
// libraries
import {AsYouType, CountryCode} from 'libphonenumber-js';
// constants
import {NotNumber} from '@onlineShop/constants';
// rxjs
import {BehaviorSubject} from 'rxjs';

@Directive({
  selector: 'input[phoneFormat]'
})
export class PhoneFormatDirective implements OnDestroy {
  @Input() country: CountryCode = 'TJ';
  @Input() length = 9;

  private _el: NgControl;
  private _lastNumber$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private ngControl: NgControl) {
    this._el = ngControl;
  }

  @HostListener('input', ['$event.target.value']) onInput(value: string): void {
    try {
      let numb = value.replace(NotNumber, '').toString();
      if (numb.length > this.length) {
        numb = this._lastNumber$.value;
      }
      this._lastNumber$.next(numb);
      try {
        const asYouType = new AsYouType(this.country);
        const formatPhone = asYouType.input(numb);
        this._el.control.patchValue(formatPhone);
      } catch (error) {
        this._el.control.patchValue(numb);
      }
    } catch (error) {
      this._el.control.patchValue(null);
    }
  }

  ngOnDestroy(): void {
    this._lastNumber$.next(null);
    this._lastNumber$.complete();
  }
}
