import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
// models
import {RecoveryVerifyRoute} from '@onlineShop/models';
// constants
import {AppRoutesFull} from '@onlineShop/constants';
// rxjs
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecoveryFinishGuard implements CanActivate {
  constructor(private _router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const recoveryVerifyRoute = this._router.getCurrentNavigation().extras.state as RecoveryVerifyRoute;
    if (recoveryVerifyRoute &&
      recoveryVerifyRoute.token &&
      recoveryVerifyRoute.username &&
      recoveryVerifyRoute.type
    ) {
      return of(true);
    } else {
      this._router.navigate([AppRoutesFull.auth.recovery.start]).then();
      return of(false);
    }
  }
}
