import {Directive, HostListener, Input, OnDestroy} from '@angular/core';
import {NgControl} from '@angular/forms';
// rxjs
import {BehaviorSubject} from 'rxjs';

@Directive({
  selector: 'input[inputMaxLength]'
})
export class InputMaxLengthDirective implements OnDestroy {
  @Input() maxLength: number;
  private _el: NgControl;
  private _lastValue$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private ngControl: NgControl) {
    this._el = ngControl;
  }

  @HostListener('input', ['$event.target.value']) onInput(value: string): void {
    try {
      if (this.maxLength) {
        let val = value.toString();
        if (val.length > this.maxLength) {
          val = this._lastValue$.value.toString();
        }
        this._lastValue$.next(val);
        this._el.control.patchValue(val);
      }
    } catch (error) {
      this._el.control.patchValue(null);
    }
  }

  ngOnDestroy(): void {
    this._lastValue$.next(null);
    this._lastValue$.complete();
  }
}
