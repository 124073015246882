<input #inputElement (blur)="onTouched()"
       (input)="input()"
       [disabled]="options.disabled"
       [max]="options.max"
       [min]="options.min"
       [ngClass]="{'form-control-sm': size === 'sm', 'form-control-lg': size === 'lg'}"
       [readOnly]="options.readonly"
       [value]="value"
       class="form-control input-number__input" type="number">

<div (mousedown)="add()" class="input-number__add"></div>
<div (mousedown)="sub()" class="input-number__sub"></div>
