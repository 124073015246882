import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
// rxjs
import {Observable, ReplaySubject, timer} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

export interface DialogData {
  itemName: string;
  isProfile?: boolean;
}

@Component({
  selector: 'app-dialog-after-delete',
  templateUrl: 'dialog-after-delete.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogAfterDeleteComponent implements OnInit, OnDestroy {

  disabledBtn = true;
  timer = 6;
  password: string;

  private _timer: Observable<number> = timer(0, 1000);
  private _destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this._timer
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        if (this.timer > 1) {
          this.timer--;
          this._cdr.markForCheck();
        } else {
          this.disabledBtn = false;
          this._cdr.markForCheck();
          this.timerDestroy();
        }
      });
  }

  timerDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  ngOnDestroy(): void {
    this.timerDestroy();
  }
}
