import {AppRoutes, AppRoutesFull} from '@onlineShop/constants';

export class GetRoutes {
  routes = AppRoutes;
  routesFull = AppRoutesFull;

  static addSlash = (value: string): string => `/${value}`;

  addSlash = (value: string): string => `/${value}`;
}
