import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
// modules
import {NgxPermissionsModule} from 'ngx-permissions';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {QuillModule} from 'ngx-quill';
import Quill from 'quill';
import ImageResize from 'quill-image-resize';
import {ImageDrop} from 'quill-image-drop-module';
import {NgxTinymceModule} from 'ngx-tinymce';
// import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// services
import {AuthService} from '@onlineShop/services/auth.service';
import {InterceptorService} from '@onlineShop/services/interceptor.service';
import {ConfigService} from '@onlineShop/services/config.service';
// functions
// export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
//   return new TranslateHttpLoader(http, 'locale?lang=', '&module=app');
// }
// Quill Editor
const font = Quill.import('formats/font');
font.whitelist = ['roboto', 'sans-serif', 'serif', 'monospace'];
Quill.register({
  'modules/imageResize': ImageResize,
  'modules/imageDrop': ImageDrop,
  font: true,
});

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    NgxWebstorageModule.forRoot({
      prefix: '',
      separator: '',
      caseSensitive: true
    }),
    QuillModule.forRoot({
      modules: {
        imageResize: {},
        imageDrop: true,
      }
    }),
    NgxTinymceModule.forRoot({
      baseURL: '/assets/tinymce/',
      // or cdn
      // baseURL: 'https://cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.11/'
    }),
    // TranslateModule.forRoot({
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: (HttpLoaderFactory),
    //     deps: [HttpClient]
    //   },
    //   isolate: false
    // })
  ],
  exports: [
    NgxPermissionsModule
  ]
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        AuthService,
        InterceptorService,
        ConfigService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: InterceptorService,
          multi: true
        },
      ]
    };
  }
}
