import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
// angular material
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
// services
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '@onlineShop/services/auth.service';
import {AccountService} from '@onlineShop/services/account.service';
// components
import {DialogAfterDeleteComponent} from '@onlineShop/shared/components/dialog-after-delete/dialog-after-delete';
// models
import {User} from '@onlineShop/models';
import {takeUntil} from 'rxjs/operators';
// rxjs
import {ReplaySubject} from 'rxjs';


interface Item extends User {
  title: string;
  type: 'user' | 'profile';
}

@Component({
  selector: 'app-user-modal',
  templateUrl: 'user-modal.component.html',
  styleUrls: ['./user-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserModalComponent {

  private _destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public item: Item,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<UserModalComponent>,
    private _toastrService: ToastrService,
    private _authService: AuthService,
    private _accountService: AccountService,
  ) {
    if (item && item.addresses && item.addresses.length) {
      item.addresses.sort((a, b) => Number(b.default) - Number(a.default));
    }
  }

  openDialog(): void {
    const dialogRef = this._dialog.open(DialogAfterDeleteComponent, {
      data: {itemName: 'профиль', isProfile: true}
    });

    dialogRef.afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe(password => {
        if (password) {
          this.deleteProfile(password);
        }
      });
  }

  deleteProfile(password: string): void {
    this._accountService.deleteProfile({password})
      .pipe(takeUntil(this._destroy$))
      .subscribe((data) => {
          if (data === 'success') {
            this.toastr('Ваш профиль успешно удалён.');
            this._authService.logoutServer()
              .pipe(takeUntil(this._destroy$))
              .subscribe((res) => {
                  this._authService.logout();
                },
                (res: HttpErrorResponse) => {
                  this._authService.logout();
                }
              );
            this._dialogRef.close();
          } else {
            this.toastr('Ошибка при удаления профиля.', 'error');
          }
        },
        (res: HttpErrorResponse) => {
          this.toastr(`Ошибка при удаления профиля ${res?.message} ${res?.error?.password}`, 'error');
        }
      );
  }

  private toastr(message: string, type: 'success' | 'error' | 'info' | 'warning' = 'success'): void {
    const override = {timeOut: 2000, progressBar: true};
    if (type === 'success') {
      this._toastrService.success(message, null, override);
    } else if (type === 'error') {
      this._toastrService.error(message, null, override);
    } else if (type === 'info') {
      this._toastrService.info(message, null, override);
    } else if (type === 'warning') {
      this._toastrService.warning(message, null, override);
    }
  }
}
