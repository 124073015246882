<a mat-menu-item (click)="openModal(user)">
  <mat-icon>account_circle</mat-icon>
  Мой профиль
</a>
<a *ngIf="!isMobileApp" mat-menu-item [href]="gr.routesFull.main.account.profile" target="_blank">
  <mat-icon>account_box</mat-icon>
  Изменить профиль
</a>
<a *ngIf="!isMobileApp" mat-menu-item [href]="gr.routesFull.main.account.changePassword" target="_blank">
  <mat-icon>vpn_key</mat-icon>
  Изменить пароль
</a>
<mat-divider></mat-divider>
<button mat-menu-item [matMenuTriggerFor]="roles">
  <mat-icon>supervised_user_circle</mat-icon>
  Изменить роль
</button>
<mat-menu #roles="matMenu">
  <button *ngFor="let role of userRoles" (click)="changeRole(role.role)" mat-menu-item>{{role.name}}</button>
</mat-menu>
<mat-divider></mat-divider>
<button mat-menu-item>
  <mat-checkbox (change)="changeMode($event)"
                [checked]="dark | async"
                color="primary"
                class="checkbox-dark-mode">Темный режим
  </mat-checkbox>
</button>
<mat-divider></mat-divider>
<button (click)="logout()" mat-menu-item>
  <mat-icon>exit_to_app</mat-icon>
  Выход
</button>

