import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
// services
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {CookieService} from 'ngx-cookie-service';
import {ConfigService} from '@onlineShop/services/config.service';
import {AccountService} from '@onlineShop/services/account.service';
// models
import {
  CookieToken,
  HttpResponseAPI,
  LoginRequest,
  RecoveryFinishRequest,
  RecoveryStartRequest,
  RecoveryStartResponse,
  RecoveryVerifyRequest,
  RecoveryVerifyResendCodeRequest,
  RecoveryVerifyResendCodeResponse,
  RecoveryVerifyResponse,
  RefreshTokenRequest,
  RegisterFinishRequest,
  RegisterStartRequest,
  RegisterStartResponse,
  RegisterVerifyRequest,
  RegisterVerifyResendCodeRequest,
  RegisterVerifyResendCodeResponse,
  RegisterVerifyResponse,
  Token,
  User
} from '@onlineShop/models';
// enums
import {Auth} from '@onlineShop/enums';
// constants
import {AppRoutes} from '@onlineShop/constants';
// rxjs
import {Observable, of, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';

@Injectable()
export class AuthService {
  private _url = 'client/auth';
  private _refreshTokenRequest: RefreshTokenRequest;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _location: Location,
    private _localStorageService: LocalStorageService,
    private _sessionStorageService: SessionStorageService,
    private _cookieService: CookieService,
    private _configService: ConfigService,
    private _accountService: AccountService,
  ) {
  }

  get expiresIn(): number {
    if (this.authRemember === true) {
      return JSON.parse(this._localStorageService.retrieve(Auth.EXPIRES_IN));
    } else {
      return JSON.parse(this._sessionStorageService.retrieve(Auth.EXPIRES_IN));
    }
  }

  get accessToken(): string {
    if (this.authRemember === true) {
      return JSON.parse(this._localStorageService.retrieve(Auth.ACCESS_TOKEN));
    } else {
      return JSON.parse(this._sessionStorageService.retrieve(Auth.ACCESS_TOKEN));
    }
  }

  get tokenType(): string {
    if (this.authRemember === true) {
      return JSON.parse(this._localStorageService.retrieve(Auth.TOKEN_TYPE));
    } else {
      return JSON.parse(this._sessionStorageService.retrieve(Auth.TOKEN_TYPE));
    }
  }

  get authRemember(): boolean {
    return JSON.parse(this._localStorageService.retrieve(Auth.REMEMBER));
  }

  get currentPathIsAuth(): Observable<boolean> {
    const path = this._location.path().split('/');
    const pathRoute = this._router.getCurrentNavigation().finalUrl.toString().split('/');
    return of(!!((path.length >= 2 && path[1] === AppRoutes.auth.self) ||
      ((path.length === 1 && path[0] === '') && (pathRoute.length >= 2 && pathRoute[1] === AppRoutes.auth.self))));
  }

  private get _refreshToken(): string {
    if (this.authRemember === true) {
      return JSON.parse(this._localStorageService.retrieve(Auth.REFRESH_TOKEN));
    } else {
      return JSON.parse(this._sessionStorageService.retrieve(Auth.REFRESH_TOKEN));
    }
  }

  isLoggedIn(): Observable<boolean> {
    if (!this.accessToken && !this._refreshToken) {
      return of(false);
    }

    // if (!this.accessToken && !this._refreshToken) {
    //   const accessToken = this._cookieService.get(Auth.ACCESS_TOKEN);
    //   const refreshToken = this._cookieService.get(Auth.REFRESH_TOKEN);
    //   const tokenType = this._cookieService.get(Auth.TOKEN_TYPE);
    //   const expiresIn = this._cookieService.get(Auth.EXPIRES_IN);
    //   const remember = this._cookieService.get(Auth.REMEMBER);
    //
    //   if (accessToken && refreshToken && tokenType && expiresIn && remember) {
    //     const cookieToken: CookieToken = {
    //       token: {
    //         access_token: accessToken.toString(),
    //         refresh_token: refreshToken.toString(),
    //         token_type: tokenType.toString(),
    //         expires_in: Number(expiresIn),
    //       },
    //       remember: Boolean(remember)
    //     };
    //
    //     this.setTokenFromCookie(cookieToken);
    //   } else {
    //     return of(false);
    //   }
    // }

    if (this._accountService.currentUser && this.expiresIn && this.expiresIn > Date.now()) {
      return of(true);
    }

    return this._accountService.getCurrentUser()
      .pipe(
        map((user: User) => {
          if (!user || !user.id) {
            return false;
          }
          return !!(user && user.id);
        }),
        shareReplay(1),
        catchError((err) => {
          return of(false);
        })
      );
  }

  login(body: LoginRequest): Observable<boolean> {
    return this._http.post<HttpResponseAPI>(`${this._url}/login`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          const data = res.data as Token;
          this._setAuthRemember(body.remember || false);
          this._setToken(data);
          return true;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  refreshToken(): Observable<Token> {
    if (this._refreshToken) {
      this._refreshTokenRequest = {
        refresh_token: this._refreshToken,
        scope: ''
      };

      return this._http.post<HttpResponseAPI>(`${this._url}/login/refresh`, this._refreshTokenRequest)
        .pipe(
          map((res: HttpResponseAPI) => {
            const data = res.data as Token;
            this._setAuthRemember(this.authRemember || false);
            this._setToken(data);
            return data;
          }),
          shareReplay(1),
          catchError((err: HttpErrorResponse) => throwError(err))
        );
    } else {
      return throwError({error: 'Refresh Token isNull'});
    }
  }

  registerStart(body: RegisterStartRequest): Observable<RegisterStartResponse> {
    return this._http.post<HttpResponseAPI>(`${this._url}/register/start`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          return res.data as RegisterStartResponse;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  registerVerify(body: RegisterVerifyRequest): Observable<RegisterVerifyResponse> {
    return this._http.post<HttpResponseAPI>(`${this._url}/register/verify`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          return res.data as RegisterVerifyResponse;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  registerVerifyResendCode(body: RegisterVerifyResendCodeRequest): Observable<RegisterVerifyResendCodeResponse> {
    return this._http.post<HttpResponseAPI>(`${this._url}/register/verify/resend-code`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          return res.data as RegisterVerifyResendCodeResponse;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  registerFinish(body: RegisterFinishRequest): Observable<Token> {
    return this._http.post<HttpResponseAPI>(`${this._url}/register/finish`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          const data = res.data as Token;
          this._setAuthRemember(true);
          this._setToken(data);
          return data;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  recoveryStart(body: RecoveryStartRequest): Observable<RecoveryStartResponse> {
    return this._http.post<HttpResponseAPI>(`${this._url}/recovery/start`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          return res.data as RecoveryStartResponse;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  recoveryVerify(body: RecoveryVerifyRequest): Observable<RecoveryVerifyResponse> {
    return this._http.post<HttpResponseAPI>(`${this._url}/recovery/verify`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          return res.data as RecoveryVerifyResponse;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  recoveryVerifyResendCode(body: RecoveryVerifyResendCodeRequest): Observable<RecoveryVerifyResendCodeResponse> {
    return this._http.post<HttpResponseAPI>(`${this._url}/recovery/verify/resend-code`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          return res.data as RecoveryVerifyResendCodeResponse;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  recoveryFinish(body: RecoveryFinishRequest): Observable<Token> {
    return this._http.post<HttpResponseAPI>(`${this._url}/recovery/finish`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          const data = res.data as Token;
          this._setAuthRemember(true);
          this._setToken(data);
          return data;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  logout(): void {
    this._accountService.clearUser();
    this._deleteAllCookieAndStorage();
    this._router.navigateByUrl(AppRoutes.auth.self).then();
  }

  logoutServer(body: any = null): Observable<boolean> {
    return this._http.post<HttpResponseAPI>(`${this._url}/logout`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          return res.data as boolean;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  setTokenFromCookie(cookieToken: CookieToken): void {
    this._setAuthRemember(cookieToken.remember);
    this._setToken(cookieToken.token);
  }

  private _setExpiresIn(value: number): void {
    if (value) {
      const expires = value * 1000 + Date.now();
      // this._configService.expiresCookie(new Date(expires));
      if (this.authRemember === true) {
        this._configService.expiresCookie(90);
        this._localStorageService.store(Auth.EXPIRES_IN, JSON.stringify(expires));
      } else {
        this._configService.expiresCookie(0);
        this._sessionStorageService.store(Auth.EXPIRES_IN, JSON.stringify(expires));
      }
      // this._cookieService.set(Auth.EXPIRES_IN, expires.toString(), this._configService.optionCookie);
    }
  }

  private _setAccessToken(value: string): void {
    if (value) {
      // const expires = new Date(this.expiresIn);
      // this._configService.expiresCookie(expires);
      if (this.authRemember === true) {
        this._configService.expiresCookie(90);
        this._localStorageService.store(Auth.ACCESS_TOKEN, JSON.stringify(value));
      } else {
        this._configService.expiresCookie(0);
        this._sessionStorageService.store(Auth.ACCESS_TOKEN, JSON.stringify(value));
      }
      // this._cookieService.set(Auth.ACCESS_TOKEN, value.toString(), this._configService.optionCookie);
    }
  }

  private _setRefreshToken(value: string): void {
    if (value) {
      if (this.authRemember === true) {
        this._configService.expiresCookie(90);
        this._localStorageService.store(Auth.REFRESH_TOKEN, JSON.stringify(value));
      } else {
        this._configService.expiresCookie(0);
        this._sessionStorageService.store(Auth.REFRESH_TOKEN, JSON.stringify(value));
      }
      // this._cookieService.set(Auth.REFRESH_TOKEN, value.toString(), this._configService.optionCookie);
    }
  }

  private _setTokenType(value: string): void {
    if (value) {
      if (this.authRemember === true) {
        this._configService.expiresCookie(90);
        this._localStorageService.store(Auth.TOKEN_TYPE, JSON.stringify(value));
      } else {
        this._configService.expiresCookie(0);
        this._sessionStorageService.store(Auth.TOKEN_TYPE, JSON.stringify(value));
      }
      // this._cookieService.set(Auth.TOKEN_TYPE, value.toString(), this._configService.optionCookie);
    }
  }

  private _setAuthRemember(value: boolean): void {
    if (value === true || value === false) {
      this._localStorageService.store(Auth.REMEMBER, JSON.stringify(value));
      if (value === true) {
        this._configService.expiresCookie(90);
      } else {
        this._configService.expiresCookie(0);
      }
      // this._cookieService.set(Auth.REMEMBER, value.toString(), this._configService.optionCookie);
    }
  }

  private _setToken(token: Token): void {
    if (this.authRemember === true) {
      this._sessionStorageService.clear(Auth.ACCESS_TOKEN);
      this._sessionStorageService.clear(Auth.REFRESH_TOKEN);
      this._sessionStorageService.clear(Auth.TOKEN_TYPE);
      this._sessionStorageService.clear(Auth.EXPIRES_IN);
    } else {
      this._localStorageService.clear(Auth.ACCESS_TOKEN);
      this._localStorageService.clear(Auth.REFRESH_TOKEN);
      this._localStorageService.clear(Auth.TOKEN_TYPE);
      this._localStorageService.clear(Auth.EXPIRES_IN);
    }
    this._setExpiresIn(token.expires_in);
    this._setAccessToken(token.access_token);
    this._setRefreshToken(token.refresh_token);
    this._setTokenType(token.token_type);
  }

  private _deleteAllCookieAndStorage(): void {
    this._localStorageService.clear();
    this._sessionStorageService.clear();
    this._cookieService.deleteAll(
      this._configService.optionCookie.path,
      this._configService.optionCookie.domain,
      this._configService.optionCookie.secure,
      this._configService.optionCookie.sameSite
    );
  }
}
