import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';
// constants
import {NotOnlyAllAlphabet} from '@onlineShop/constants';

@Directive({
  selector: 'input[onlyAllAlphabet]'
})
export class InputOnlyAllAlphabetDirective {
  private _el: NgControl;

  constructor(private ngControl: NgControl) {
    this._el = ngControl;
  }

  @HostListener('input', ['$event.target.value']) onInput(value: string): void {
    try {
      const onlyAllAlphabet = value.replace(NotOnlyAllAlphabet, '').toString();
      this._el.control.patchValue(onlyAllAlphabet);
    } catch (error) {
      this._el.control.patchValue(null);
    }
  }
}
