import {ApplicationRef, DoBootstrap, Inject, NgModule, NgZone, PLATFORM_ID} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {Router, RouterModule} from '@angular/router';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
// modules
import {ToastrModule} from 'ngx-toastr';
import {CoreModule} from './core/core.module';
import {SharedModule} from '@onlineShop/shared/shared.module';
// services
import {AuthService} from '@onlineShop/services/auth.service';
// components
import {AppComponent} from './app.component';
// routes
import {APP_ROUTES} from './app-routes';
// constants
import {AppRoutes} from '@onlineShop/constants';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule.forRoot(),
    RouterModule.forRoot(APP_ROUTES, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabled'
    }),
    ToastrModule.forRoot({
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true
    }),
    SharedModule,
  ]
})
export class AppModule implements DoBootstrap {

  constructor(
    @Inject(PLATFORM_ID) private _platformId: any,
    @Inject(DOCUMENT) private _document: Document,
    private _zone: NgZone,
    private _router: Router,
    private _authService: AuthService,
  ) {
  }

  async ngDoBootstrap(appRef: ApplicationRef): Promise<void> {

    try {
      const isLoggedIn = await this._authService.isLoggedIn().toPromise();
      if (!isLoggedIn) {
        const currentPathIsAuth = await this._authService.currentPathIsAuth.toPromise();
        if (!currentPathIsAuth) {
          await this._router.navigateByUrl(AppRoutes.auth.self).then(() => this.disabledLoader());
        } else {
          this.disabledLoader();
        }
      } else {
        this.disabledLoader();
      }
    } catch (e) {
      await this._router.navigateByUrl(AppRoutes.auth.self).then(() => this.disabledLoader());
    }

    appRef.bootstrap(AppComponent);
    return Promise.resolve();
  }

  private disabledLoader(): void {
    if (isPlatformBrowser(this._platformId)) {
      this._zone.runOutsideAngular(() => {
        const preloader = this._document.querySelector('.site-preloader');
        preloader.addEventListener('transitionend', (event: TransitionEvent) => {
          if (event.propertyName === 'opacity') {
            preloader.remove();
          }
        });
        preloader.classList.add('site-preloader__fade');
      });
    }
  }
}
