import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
// services
import {BaseService} from './base.service';
// models
import {HttpResponseAPI, Region} from '@onlineShop/models';
// functions
import {getQueryParams} from '@onlineShop/functions';
// rxjs
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegionService extends BaseService<Region> {
  protected url = 'client/general/info/regions';
  private _url = 'cp/regions';

  constructor(
    protected _http: HttpClient,
  ) {
    super(_http);
  }

  // get regions
  getRegions(params: any = {}): Observable<HttpResponseAPI> {
    return this._http.get<HttpResponseAPI>(this._url, getQueryParams(params))
      .pipe(
        map((result: HttpResponseAPI) => {
          result.data = result.data as Region[];
          return result;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // update region country
  updateRegionActive(regionId: number, active: boolean): Observable<boolean> {
    const body = {active, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this._url}/${regionId}/update-active`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as boolean;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }
}
