import {Directive, HostListener, Input, OnDestroy} from '@angular/core';
import {NgControl} from '@angular/forms';
// constants
import {NotNumber} from '@onlineShop/constants';
// rxjs
import {BehaviorSubject} from 'rxjs';

@Directive({
  selector: 'input[inputOnlyNumber]'
})
export class InputOnlyNumberDirective implements OnDestroy {
  @Input() min: number;
  @Input() max: number;
  private _el: NgControl;
  private _lastNumber$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private ngControl: NgControl) {
    this._el = ngControl;
  }

  @HostListener('input', ['$event.target.value']) onInput(value: string): void {
    try {
      let numb: string | number = value.replace(NotNumber, '').toString();
      if (numb !== '') {
        if (this.min) {
          numb = parseInt(numb, 10);
          if (this.min > numb) {
            const minLength = this.min.toString().length;
            const maxLength = this.max.toString().length;
            const numberLength = numb.toString().length;
            const min = Math.trunc(this.min / Math.pow(10, (minLength - numberLength)));
            const max = Math.trunc(this.max / Math.pow(10, (maxLength - numberLength)));
            if (min > numb || max < numb) {
              numb = this._lastNumber$.value;
            }
          }
        }
        if (this.max) {
          if (typeof numb === 'string') {
            numb = parseInt(numb, 10);
          }
          if (this.max < numb) {
            numb = this._lastNumber$.value;
          }
        }
        this._lastNumber$.next(numb.toString());
        this._el.control.patchValue(numb.toString());
      } else {
        this._lastNumber$.next(null);
        this._el.control.patchValue(null);
      }
    } catch (error) {
      this._el.control.patchValue(null);
    }
  }

  ngOnDestroy(): void {
    this._lastNumber$.next(null);
    this._lastNumber$.complete();
  }
}
