import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
// services
import {BaseService} from './base.service';
// models
import {HttpResponseAPI, Product, ProductRequest} from '@onlineShop/models';
// functions
import {getQueryParams} from '@onlineShop/functions';
// rxjs
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService<Product> {

  protected url = 'cp/products';

  constructor(
    protected _http: HttpClient,
  ) {
    super(_http);
  }

  // get products
  getProducts(params: any = {}): Observable<HttpResponseAPI> {
    return this._http.get<HttpResponseAPI>(this.url, getQueryParams(params))
      .pipe(
        map((result: HttpResponseAPI) => {
          result.data = result.data as Product[];
          return result;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // create product
  createProduct(body: ProductRequest): Observable<boolean> {
    return this._http.post<HttpResponseAPI>(this.url, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as boolean;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // update product
  updateProduct(body: ProductRequest): Observable<boolean> {
    body = {...body, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this.url}/${body.id}`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as boolean;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // get product image base64
  getProductImage(productId: number, image: string): Observable<string> {
    return this._http.get<HttpResponseAPI>(`${this.url}/${productId}/images/${image}`)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data?.image?.length ? result.data.image.toString() : null;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // create product image
  createProductImage(productId: number, image: string): Observable<string> {
    return this._http.post<HttpResponseAPI>(`${this.url}/${productId}/images`, {images: [image]})
      .pipe(
        map((result: HttpResponseAPI) => {
          return result?.data?.length ? result.data[0] as string : null;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // delete product image
  deleteProductImage(productId: number, image: string): Observable<boolean> {
    const body = {_method: 'DELETE'};

    return this._http.post<HttpResponseAPI>(`${this.url}/${productId}/images/${image}`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result && result.data ? result.data : true;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // reorder product image
  reorderProductImage(productId: number, images: string[]): Observable<boolean> {
    return this._http.post<HttpResponseAPI>(`${this.url}/${productId}/images/reorder`, {images})
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as boolean;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // update product active
  updateProductActive(productId: number, active: boolean): Observable<boolean> {
    const body = {active, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this.url}/${productId}/update-active`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as boolean;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // to data url
  toDataUrl(url, callback): any {
    const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', proxyUrl + url);
    xhr.responseType = 'blob';
    xhr.send();
  }
}
