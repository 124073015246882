import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
// services
import {NgxPermissionsService, NgxRole, NgxRolesService} from 'ngx-permissions';
// models
import {HttpResponseAPI, Role, User} from '@onlineShop/models';
// rxjs
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private _url = 'client/users/profile';

  private _isLoggedInSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly isLoggedIn$: Observable<boolean> = this._isLoggedInSubject$.asObservable();

  private _currentUserSubject$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  readonly currentUser$: Observable<User> = this._currentUserSubject$.asObservable();

  constructor(
    private _http: HttpClient,
    private _ngxPermissionsService: NgxPermissionsService,
    private _ngxRoleService: NgxRolesService,
    private _router: Router,
    private _localStorageService: LocalStorageService,
  ) {
  }

  get currentUser(): User | null {
    return this._currentUserSubject$.value;
  }

  get isLoggedIn(): boolean {
    return this._isLoggedInSubject$.value;
  }

  get roleInStorage(): string {
    return JSON.parse(this._localStorageService.retrieve('ROLE'));
  }

  get role(): Role {
    let result: Role = null;

    this.roles.forEach(r => {
      if (r.role === this.roleInStorage) {
        result = r;
      }
    });

    return result;
  }

  get roles(): Role[] {
    const tmp = this.currentUser.roles;
    const roles = Object.entries(this._ngxRoleService.getRoles());
    const result: Role[] = [];
    tmp.forEach(t => {
      roles.forEach(r => {
        if (t.role === r[1].name) {
          result.push(t);
        }
      });
    });
    return result;
  }

  getCurrentUser(): Observable<User> {
    return this._http.get<HttpResponseAPI>(this._url)
      .pipe(
        map((res: HttpResponseAPI) => {
          const data = res.data as User;
          this.setCurrentUser(data);
          return data;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  changePassword(body): Observable<User> {
    body = {...body, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this._url}/change-password`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          const data = res.data as User;
          this.setCurrentUser(data);
          return data;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  editProfile(body): Observable<User> {
    body = {...body, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(this._url, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          const data = res.data as User;
          this.setCurrentUser(data);
          return data;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  changeAvatar(body): Observable<User> {
    body = {...body, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this._url}/change-avatar`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          const data = res.data as User;
          this.setCurrentUser(data);
          return data;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  deleteProfile(body): Observable<any> {
    body = {...body, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this._url}/delete-profile`, body)
      .pipe(
        map((res: HttpResponseAPI) => {
          return res.data;
        }),
        shareReplay(1),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  clearUser(): void {
    this._currentUserSubject$.next(null);
    this._isLoggedInSubject$.next(false);
    this._ngxRoleService.flushRolesAndPermissions();
  }

  private setCurrentUser(user: User | null): void {
    if (user && user.id && user.roles && user.roles.length) {
      this._ngxRoleService.flushRolesAndPermissions();
      user.roles.forEach(role => {
        if (role.role !== 'Client') {
          this._ngxRoleService.addRole(role.role, role.permissions.map(p => p.permission));
        }
      });

      if (Object.entries(this._ngxRoleService.getRoles()).length) {
        this._currentUserSubject$.next(user);
        this._isLoggedInSubject$.next(!!(user && user.id));
        this.setUserRole();

      } else {
        window.location.href = 'https://tozon.tj';
      }
    } else {
      window.location.href = 'https://tozon.tj';
    }

  }

  setUserRole(value: string = null): void {
    const roles = Object.entries(this._ngxRoleService.getRoles());
    if (roles.length && this.currentUser && this.currentUser.id) {
      let role: NgxRole;
      const permission: string[] = this.currentUser.permissions.map(p => p.permission);
      this._ngxPermissionsService.flushPermissions();

      if (value) {
        roles.forEach(r => {
          if (r[1].name === value) {
            role = r[1];
            this._setRoleInStorage(role.name);
          }
        });
      } else if (this.roleInStorage) {
        roles.forEach(r => {
          if (r[1].name === this.roleInStorage) {
            role = r[1];
          }
        });
      }

      if (!role) {
        role = roles[0][1];
        this._setRoleInStorage(role.name);
      }

      permission.push(...(role.validationFunction as string[]));
      this._ngxPermissionsService.loadPermissions(permission);
    }
  }

  hasPerms(perms: string[]): boolean {
    const permsUser: string[] = Object.entries(this._ngxPermissionsService.getPermissions()).map(r => r[0]);
    return permsUser.some(r => perms.includes(r));
  }

  private _setRoleInStorage(value: string): void {
    if (value) {
      this._localStorageService.store('ROLE', JSON.stringify(value));
    }
  }

}
