// Perfect Scrollbar Config
import {PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';

export const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

export const EMPTY_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};
