import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
// services
import {BaseService} from './base.service';
// models
import {Address, AddressRequest, DeleteAddressRequest, HttpResponseAPI} from '@onlineShop/models';
// function
import {getQueryParams} from '@onlineShop/functions';
// rxjs
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AddressService extends BaseService<Address> {

  protected url = 'client/users/profile/addresses';
  private _url = 'cp/users';

  constructor(
    protected _http: HttpClient,
  ) {
    super(_http);
  }

  // get addresses
  getAddresses(userId: number, params: any = {}): Observable<Address[]> {
    return this._http.get<HttpResponseAPI>(`${this._url}/${userId}/addresses`, getQueryParams(params))
      .pipe(
        map((result: HttpResponseAPI) => {
          const data = result.data as Address[];
          return this._normalizeResponseData(data || []);
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // create address
  createAddress(body: AddressRequest): Observable<Address[]> {
    return this._http.post<HttpResponseAPI>(`${this._url}/${body.userId}/addresses`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          const data = result.data as Address[];
          return this._normalizeResponseData(data || []);
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // update address
  updateAddress(body: AddressRequest): Observable<Address[]> {
    body = {...body, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this._url}/${body.userId}/addresses/${body.id}`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          const data = result.data as Address[];
          return this._normalizeResponseData(data || []);
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // delete address
  deleteAddress(body: DeleteAddressRequest): Observable<Address[]> {
    body = {...body, _method: 'DELETE'};

    return this._http.post<HttpResponseAPI>(`${this._url}/${body.userId}/addresses/${body.id}`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          const data = result.data as Address[];
          return this._normalizeResponseData(data || []);
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  protected sort = (a: Address, b: Address) => {
    return Number(b.default) - Number(a.default);
  }
}
