import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RouterModule} from '@angular/router';
// material
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSelectModule} from '@angular/material/select';
import {MatDividerModule} from '@angular/material/divider';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatDialogModule} from '@angular/material/dialog';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatChipsModule} from '@angular/material/chips';
import {MatTreeModule} from '@angular/material/tree';
import {MatProgressBarModule} from '@angular/material/progress-bar';
// modules
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {RedZoomModule} from 'ngx-red-zoom';
import {FileUploadModule} from '@iplab/ngx-file-upload';
import {ImageCropperModule} from 'ngx-image-cropper';
import {QuillModule} from 'ngx-quill';
import {NgxTinymceModule} from 'ngx-tinymce';
import {TreeModule} from '@circlon/angular-tree-component';
import {TooltipModule} from 'ng2-tooltip-directive';
import {NgxPermissionsModule, NgxPermissionsRestrictStubModule} from 'ngx-permissions';
import {ChartsModule} from 'ng2-charts';
// directives
import {AccordionAnchorDirective, AccordionDirective, AccordionLinkDirective} from './directives/accordion';
import {PhoneFormatDirective} from '@onlineShop/directives/phone-format.directive';
import {PasswordFormatDirective} from '@onlineShop/directives/password-format.directive';
import {InputMaxLengthDirective} from '@onlineShop/directives/input-max-length.directive';
import {InputOnlyNumberDirective} from '@onlineShop/directives/input-only-number.directive';
import {InputOnlyAllAlphabetDirective} from '@onlineShop/directives/input-only-all-alphabet.directive';
import {InputOnlyNumberFloatDirective} from './directives/input-only-number-float.directive';
// components
import {IconComponent} from './components/icon/icon.component';
import {InputNumberComponent} from './components/input-number/input-number.component';
import {LoadingBarComponent} from './components/loading-bar/loading-bar.component';
import {MenuProfileComponent} from './components/menu-profile/menu-profile.component';
import {DialogAfterDeleteComponent} from './components/dialog-after-delete/dialog-after-delete';
import {QuillEditorComponent} from './components/quill-editor/quill-editor.component';
import {TinymceEditorComponent} from './components/tinymce-editor/tinymce-editor.component';
import {UserModalComponent} from './components/user-modal/user-modal.component';
import {AddressFormModalComponent} from './components/address-form-modal/address-form-modal.component';
import {OrderModalComponent} from '@onlineShop/shared/components/order-modal/order-modal.component';
import {DialogAfterChangeComponent} from '@onlineShop/shared/components/dialog-after-change/dialog-after-change';
import {OrderItemFormModalComponent} from '@onlineShop/shared/components/order-item-form-modal/order-item-form-modal.component';
// pipes
import {RemoveCommaPipe} from '@onlineShop/pipes/remove-comma.pipe';
// constants
import {DEFAULT_PERFECT_SCROLLBAR_CONFIG} from '@onlineShop/constants';

const material = [
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatListModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatToolbarModule,
  MatSelectModule,
  MatDividerModule,
  MatSortModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  DragDropModule,
  MatChipsModule,
  MatTreeModule,
  MatProgressBarModule,
];

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  NgxMatSelectSearchModule,
  PerfectScrollbarModule,
  CarouselModule,
  RedZoomModule,
  FileUploadModule,
  ImageCropperModule,
  QuillModule,
  NgxTinymceModule,
  TreeModule,
  TooltipModule,
  NgxPermissionsRestrictStubModule,
  NgxPermissionsModule,
  ChartsModule,
];

const components = [
  // directives
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
  PhoneFormatDirective,
  PasswordFormatDirective,
  InputMaxLengthDirective,
  InputOnlyNumberDirective,
  InputOnlyAllAlphabetDirective,
  InputOnlyNumberFloatDirective,
  // components
  IconComponent,
  InputNumberComponent,
  LoadingBarComponent,
  MenuProfileComponent,
  DialogAfterDeleteComponent,
  QuillEditorComponent,
  TinymceEditorComponent,
  UserModalComponent,
  AddressFormModalComponent,
  OrderModalComponent,
  DialogAfterChangeComponent,
  OrderItemFormModalComponent,
  // pipes
  RemoveCommaPipe
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
    ...material,
    ...modules,
    RouterModule,
  ],
  exports: [
    ...material,
    ...modules,
    ...components,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
  ],
})

export class SharedModule {
}
