import {Menu} from '@onlineShop/models';
// constants
import {AppRoutesFull} from './app-routes';
// enums
import {Perms} from '@onlineShop/enums';

export const MenuItems: Menu[] = [
  // Main
  {
    name: 'Главная',
    type: 'separator',
    permissions: [
      Perms.dashboardAll,
      Perms.dashboardView,
    ],
  },
  {
    state: AppRoutesFull.main.dashboard,
    name: 'Панель',
    type: 'link',
    icon: 'dashboard',
    permissions: [
      Perms.dashboardAll,
      Perms.dashboardView,
    ],
  },
  // Orders
  {
    name: 'Заказы',
    type: 'separator',
    permissions: [
      Perms.orderAll,
      Perms.orderCreate,
      Perms.orderViewAny,
    ],
  },
  {
    state: AppRoutesFull.main.orders.list.unfinished,
    name: 'Незавершенные',
    type: 'link',
    icon: 'receipt_long',
    permissions: [
      Perms.orderAll,
      Perms.orderViewAny,
    ],
  },
  {
    name: 'Заказы',
    type: 'sub',
    icon: 'list_alt',
    permissions: [
      Perms.orderAll,
      Perms.orderCreate,
      Perms.orderViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.orders.list.self,
        name: 'Все заказы',
        type: 'link',
        permissions: [
          Perms.orderAll,
          Perms.orderViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.orders.list.inProcesses,
        name: 'В процессах',
        type: 'link',
        permissions: [
          Perms.orderAll,
          Perms.orderViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.orders.list.completed,
        name: 'Завершенные',
        type: 'link',
        permissions: [
          Perms.orderAll,
          Perms.orderViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.orders.list.canceled,
        name: 'Отмененные',
        type: 'link',
        permissions: [
          Perms.orderAll,
          Perms.orderViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.orders.list.carts,
        name: 'Корзины',
        type: 'link',
        permissions: [
          Perms.orderAll,
          Perms.orderViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.orders.add,
        name: 'Добавить заказ',
        type: 'link',
        permissions: [
          Perms.orderAll,
          Perms.orderCreate,
        ],
      },
    ],
  },

  // Management
  {
    name: 'Управления',
    type: 'separator',
    permissions: [
      Perms.storeAll,
      Perms.storeCreate,
      Perms.storeViewAny,
      Perms.pointAll,
      Perms.pointCreate,
      Perms.pointViewAny,
      Perms.supplierAll,
      Perms.supplierCreate,
      Perms.supplierViewAny,
    ],
  },
  {
    name: 'Магазины',
    type: 'sub',
    icon: 'store',
    permissions: [
      Perms.storeAll,
      Perms.storeCreate,
      Perms.storeViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.stores.list,
        name: 'Все магазины',
        type: 'link',
        permissions: [
          Perms.storeAll,
          Perms.storeViewAny,
        ],
      },
      // {
      //   state: AppRoutesFull.main.stores.add,
      //   name: 'Добавить магазин',
      //   type: 'link',
      //   permissions: [
      //     Perms.storeAll,
      //     Perms.storeCreate,
      //   ],
      // },
    ]
  },
  {
    name: 'Склады',
    type: 'sub',
    icon: 'border_all',
    permissions: [
      Perms.pointAll,
      Perms.pointCreate,
      Perms.pointViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.points.list,
        name: 'Все склады',
        type: 'link',
        permissions: [
          Perms.pointAll,
          Perms.pointViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.points.add,
        name: 'Добавить склад',
        type: 'link',
        permissions: [
          Perms.pointAll,
          Perms.pointCreate,
        ],
      },
    ]
  },
  {
    name: 'Поставщики',
    type: 'sub',
    icon: 'people_alt',
    permissions: [
      Perms.supplierAll,
      Perms.supplierCreate,
      Perms.supplierViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.suppliers.list,
        name: 'Все поставщики',
        type: 'link',
        permissions: [
          Perms.supplierAll,
          Perms.supplierViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.suppliers.add,
        name: 'Добавить поставщика',
        type: 'link',
        permissions: [
          Perms.supplierAll,
          Perms.supplierCreate,
        ],
      },
    ]
  },

  // Handbooks
  {
    name: 'Справочники',
    type: 'separator',
    permissions: [
      Perms.productAll,
      Perms.productCreate,
      Perms.productViewAny,
      Perms.categoryAll,
      Perms.categoryCreate,
      Perms.categoryViewAny,
      Perms.propertyGroupAll,
      Perms.propertyGroupCreate,
      Perms.propertyGroupViewAny,
      Perms.manufacturerAll,
      Perms.manufacturerCreate,
      Perms.manufacturerViewAny,
      Perms.badgeAll,
      Perms.badgeCreate,
      Perms.badgeViewAny,
      Perms.unitAll,
      Perms.unitCreate,
      Perms.unitViewAny,
      Perms.stockStatusAll,
      Perms.stockStatusCreate,
      Perms.stockStatusViewAny,
      Perms.currencyAll,
      Perms.currencyCreate,
      Perms.currencyViewAny,
      Perms.currencyRateAll,
      Perms.currencyRateViewAny,
      Perms.countryAll,
      Perms.countryCreate,
      Perms.countryViewAny,
      Perms.regionAll,
      Perms.regionCreate,
      Perms.regionViewAny,
      Perms.cityAll,
      Perms.cityCreate,
      Perms.cityViewAny,
    ],
  },
  {
    name: 'Товары',
    type: 'sub',
    icon: 'art_track',
    permissions: [
      Perms.productAll,
      Perms.productCreate,
      Perms.productViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.products.list,
        name: 'Все товары',
        type: 'link',
        permissions: [
          Perms.productAll,
          Perms.productViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.products.add, name: 'Добавить товар', type: 'link',
        permissions: [
          Perms.productAll,
          Perms.productCreate,
        ],
      },
    ]
  },
  {
    name: 'Категории',
    type: 'sub',
    icon: 'format_list_bulleted',
    permissions: [
      Perms.categoryAll,
      Perms.categoryCreate,
      Perms.categoryViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.categories.list,
        name: 'Все категории',
        type: 'link',
        permissions: [
          Perms.categoryAll,
          Perms.categoryViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.categories.add,
        name: 'Добавить категорию',
        type: 'link',
        permissions: [
          Perms.categoryAll,
          Perms.categoryCreate,
        ],
      },
    ]
  },
  {
    name: 'Свойства товаров',
    type: 'sub',
    icon: 'format_align_right',
    permissions: [
      Perms.propertyGroupAll,
      Perms.propertyGroupCreate,
      Perms.propertyGroupViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.properties.list,
        name: 'Все св. товаров',
        type: 'link',
        permissions: [
          Perms.propertyGroupAll,
          Perms.propertyGroupViewAny,
        ],
      }, // Все свойства товаров
      {
        state: AppRoutesFull.main.properties.add,
        name: 'Добавить св. товара',
        type: 'link',
        permissions: [
          Perms.propertyGroupAll,
          Perms.propertyGroupCreate,
        ],
      }, // Добавить свойства товаров
    ]
  },
  {
    name: 'Производители',
    type: 'sub',
    icon: 'pages',
    permissions: [
      Perms.manufacturerAll,
      Perms.manufacturerCreate,
      Perms.manufacturerViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.manufacturers.list,
        name: 'Все производители',
        type: 'link',
        permissions: [
          Perms.manufacturerAll,
          Perms.manufacturerViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.manufacturers.add,
        name: 'Добавить произво.',
        type: 'link',
        permissions: [
          Perms.manufacturerAll,
          Perms.manufacturerCreate,
        ],
      }, // Добавить производителя
    ]
  },
  {
    name: 'Бирки',
    type: 'sub',
    icon: 'local_offer',
    permissions: [
      Perms.badgeAll,
      Perms.badgeCreate,
      Perms.badgeViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.badges.list,
        name: 'Все бирки',
        type: 'link',
        permissions: [
          Perms.badgeAll,
          Perms.badgeViewAny,
        ],
      },
      // {
      //   state: AppRoutesFull.main.badges.add,
      //   name: 'Добавить бирку',
      //   type: 'link',
      //   permissions: [
      //     Perms.badgeAll,
      //     Perms.badgeCreate,
      //   ],
      // },
    ]
  },
  {
    name: 'Ед. измерения', // Единицы измерения
    type: 'sub',
    icon: 'format_shapes',
    permissions: [
      Perms.unitAll,
      Perms.unitCreate,
      Perms.unitViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.units.list,
        name: 'Все ед. измерения',
        type: 'link',
        permissions: [
          Perms.unitAll,
          Perms.unitViewAny,
        ],
      }, // Все единицы измерения
      // {
      //   state: AppRoutesFull.main.units.add,
      //   name: 'Добавить ед. из.',
      //   type: 'link',
      //   permissions: [
      //     Perms.unitAll,
      //     Perms.unitCreate,
      //   ],
      // }, // Добавить единицу измерения
    ]
  },
  {
    name: 'Состояния тов.', // Состояния товара
    type: 'sub',
    icon: 'pending',
    permissions: [
      Perms.stockStatusAll,
      Perms.stockStatusCreate,
      Perms.stockStatusViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.stockStatuses.list,
        name: 'Все состояния тов.',
        type: 'link',
        permissions: [
          Perms.stockStatusAll,
          Perms.stockStatusViewAny,
        ],
      }, // Все состояния товара
      // {
      //   state: AppRoutesFull.main.stockStatuses.add,
      //   name: 'Добавить сос. тов.',
      //   type: 'link',
      //   permissions: [
      //     Perms.stockStatusAll,
      //     Perms.stockStatusCreate,
      //   ],
      // }, // Добавить состояние товара
    ]
  },
  {
    name: 'Валюты',
    type: 'sub',
    icon: 'local_atm',
    permissions: [
      Perms.currencyAll,
      Perms.currencyCreate,
      Perms.currencyViewAny,
      Perms.currencyRateAll,
      Perms.currencyRateViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.currencies.list,
        name: 'Все валюты',
        type: 'link',
        permissions: [
          Perms.currencyAll,
          Perms.currencyViewAny,
        ],
      },
      // {
      //   state: AppRoutesFull.main.currencies.add,
      //   name: 'Добавить валюту',
      //   type: 'link',
      //   permissions: [
      //     Perms.currencyAll,
      //     Perms.currencyCreate,
      //   ],
      // },
      {
        state: AppRoutesFull.main.currencies.currencyRates,
        name: 'Курсы валют',
        type: 'link',
        permissions: [
          Perms.currencyRateAll,
          Perms.currencyRateViewAny,
        ],
      },
    ]
  },
  {
    name: 'Страны',
    type: 'sub',
    icon: 'flag',
    permissions: [
      Perms.countryAll,
      Perms.countryCreate,
      Perms.countryViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.countries.list,
        name: 'Все страны',
        type: 'link',
        permissions: [
          Perms.countryAll,
          Perms.countryViewAny,
        ],
      },
      // {
      //   state: AppRoutesFull.main.countries.add,
      //   name: 'Добавить страну',
      //   type: 'link',
      //   permissions: [
      //     Perms.countryAll,
      //     Perms.countryCreate,
      //   ],
      // },
    ]
  },
  {
    name: 'Регионы',
    type: 'sub',
    icon: 'apartment',
    permissions: [
      Perms.regionAll,
      Perms.regionCreate,
      Perms.regionViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.regions.list,
        name: 'Все регионы',
        type: 'link',
        permissions: [
          Perms.regionAll,
          Perms.regionViewAny,
        ],
      },
      // {
      //   state: AppRoutesFull.main.regions.add,
      //   name: 'Добавить регион',
      //   type: 'link',
      //   permissions: [
      //     Perms.regionAll,
      //     Perms.regionCreate,
      //   ],
      // },
    ]
  },
  {
    name: 'Города',
    type: 'sub',
    icon: 'location_city',
    permissions: [
      Perms.cityAll,
      Perms.cityCreate,
      Perms.cityViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.cities.list,
        name: 'Все города',
        type: 'link',
        permissions: [
          Perms.cityAll,
          Perms.cityViewAny,
        ],
      },
      // {
      //   state: AppRoutesFull.main.cities.add,
      //   name: 'Добавить город',
      //   type: 'link',
      //   permissions: [
      //     Perms.cityAll,
      //     Perms.cityCreate,
      //   ],
      // },
    ]
  },

  // Users
  {
    name: 'Пользователи',
    type: 'separator',
    permissions: [
      Perms.userAll,
      Perms.userCreate,
      Perms.userViewAny,
    ],
  },
  {
    name: 'Пользователи',
    type: 'sub',
    icon: 'people',
    permissions: [
      Perms.userAll,
      Perms.userCreate,
      Perms.userViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.users.list,
        name: 'Все пользователи',
        type: 'link',
        permissions: [
          Perms.userAll,
          Perms.userViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.users.add,
        name: 'Добавить поль.',
        type: 'link',
        permissions: [
          Perms.userAll,
          Perms.userCreate,
        ],
      }, // Добавить пользователя
    ]
  },
  // {
  //   name: 'Сотрудники',
  //   type: 'sub',
  //   icon: 'groups',
  //   permissions: [],
  //   children: [
  //     {
  //       state: AppRoutesFull.main.employees.list,
  //       name: 'Все сотрудники',
  //       type: 'link',
  //       permissions: [],
  //     },
  //     {
  //       state: AppRoutesFull.main.employees.add,
  //       name: 'Добавить сот.',
  //       type: 'link',
  //       permissions: [],
  //     }, // Добавить сотрудника
  //   ]
  // },

  // Positions
  {
    name: 'Должности',
    type: 'separator',
    permissions: [
      Perms.roleAll,
      Perms.roleCreate,
      Perms.roleViewAny,
      Perms.permissionAll,
      Perms.permissionCreate,
      Perms.permissionViewAny,
    ],
  },
  {
    name: 'Роли',
    type: 'sub',
    icon: 'admin_panel_settings',
    permissions: [
      Perms.roleAll,
      Perms.roleCreate,
      Perms.roleViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.roles.list,
        name: 'Все роли',
        type: 'link',
        permissions: [
          Perms.roleAll,
          Perms.roleViewAny,
        ],
      },
      // {
      //   state: AppRoutesFull.main.roles.add,
      //   name: 'Добавить роль',
      //   type: 'link',
      //   permissions: [
      //     Perms.roleAll,
      //     Perms.roleCreate,
      //   ],
      // },
    ]
  },
  // {
  //   name: 'Разрешения',
  //   type: 'sub',
  //   icon: 'lock',
  //   permissions: [
  //     Perms.permissionAll,
  //     Perms.permissionCreate,
  //     Perms.permissionViewAny,
  //   ],
  //   children: [
  //     {
  //       state: AppRoutesFull.main.permissions.list,
  //       name: 'Все разрешения',
  //       type: 'link',
  //       permissions: [
  //         Perms.permissionAll,
  //         Perms.permissionViewAny,
  //       ],
  //     },
  //     {
  //       state: AppRoutesFull.main.permissions.add,
  //       name: 'Добавить разрешение',
  //       type: 'link',
  //       permissions: [
  //         Perms.permissionAll,
  //         Perms.permissionCreate,
  //       ],
  //     },
  //   ]
  // },

  // Account
  // {
  //   name: 'Аккаунт',
  //   type: 'separator',
  //   permissions: [],
  // },
  // {
  //   state: AppRoutesFull.main.account.profile,
  //   name: 'Мой профиль',
  //   type: 'link',
  //   icon: 'account_box',
  //   permissions: [],
  // },
  // {
  //   name: 'Мои адреса',
  //   type: 'sub',
  //   icon: 'place',
  //   permissions: [],
  //   children: [
  //     {
  //       state: AppRoutesFull.main.account.addresses.list,
  //       name: 'Все адреса',
  //       type: 'link',
  //       permissions: [],
  //     },
  //     {
  //       state: AppRoutesFull.main.account.addresses.add,
  //       name: 'Добавить адрес',
  //       type: 'link',
  //       permissions: [],
  //     },
  //   ]
  // },
  // {
  //   state: AppRoutesFull.main.account.changePassword,
  //   name: 'Изменить пароль',
  //   type: 'link',
  //   icon: 'vpn_key',
  //   permissions: [],
  // },

  // Settings
  {
    name: 'Настройки',
    type: 'separator',
    permissions: [
      Perms.slideAll,
      Perms.slideCreate,
      Perms.slideViewAny,
      Perms.bannerAll,
      Perms.bannerCreate,
      Perms.bannerViewAny,
      Perms.editorPageAll,
      Perms.editorPageUpdate,
      Perms.languageAll,
      Perms.languageCreate,
      Perms.languageViewAny,
    ],
  },
  {
    name: 'Слайды',
    type: 'sub',
    icon: 'view_carousel',
    permissions: [
      Perms.slideAll,
      Perms.slideCreate,
      Perms.slideViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.slides.list,
        name: 'Все слайды',
        type: 'link',
        permissions: [
          Perms.slideAll,
          Perms.slideViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.slides.add,
        name: 'Добавить слайд',
        type: 'link',
        permissions: [
          Perms.slideAll,
          Perms.slideCreate,
        ],
      },
    ]
  },
  {
    name: 'Баннеры',
    type: 'sub',
    icon: 'view_stream',
    permissions: [
      Perms.bannerAll,
      Perms.bannerCreate,
      Perms.bannerViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.banners.list,
        name: 'Все баннеры',
        type: 'link',
        permissions: [
          Perms.bannerAll,
          Perms.bannerViewAny,
        ],
      },
      {
        state: AppRoutesFull.main.banners.add,
        name: 'Добавить баннер',
        type: 'link',
        permissions: [
          Perms.bannerAll,
          Perms.bannerCreate,
        ],
      },
    ]
  },
  {
    name: 'Редактор страниц',
    type: 'sub',
    icon: 'edit_note',
    permissions: [
      Perms.editorPageAll,
      Perms.editorPageUpdate,
    ],
    children: [
      {
        state: AppRoutesFull.main.editorPages.shipping,
        name: 'Доставка',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.payment,
        name: 'Оплата',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.shippingAndPayment,
        name: 'Доставка и Оплата',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.news,
        name: 'Новости',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.masters,
        name: 'Услуги мастеров',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.pickPoints,
        name: 'Пункты выдачи',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.partnership,
        name: 'Партнерство',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.aboutUs,
        name: 'О нас',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.contactUs,
        name: 'Контакты',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.mobileApp,
        name: 'Мобильное приложение',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.faq,
        name: 'FAQ',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
      {
        state: AppRoutesFull.main.editorPages.terms,
        name: 'Конфиденциальность',
        type: 'link',
        permissions: [
          Perms.editorPageAll,
          Perms.editorPageUpdate,
        ],
      },
    ]
  },
  {
    name: 'Языки',
    type: 'sub',
    icon: 'language',
    permissions: [
      Perms.languageAll,
      Perms.languageCreate,
      Perms.languageViewAny,
    ],
    children: [
      {
        state: AppRoutesFull.main.languages.list,
        name: 'Все языки',
        type: 'link',
        permissions: [
          Perms.languageAll,
          Perms.languageViewAny,
        ],
      },
      // {
      //   state: AppRoutesFull.main.languages.add,
      //   name: 'Добавить язык',
      //   type: 'link',
      //   permissions: [
      //     Perms.languageAll,
      //     Perms.languageCreate,
      //   ],
      // },
    ]
  },
  // {
  //   name: 'Внешние ссылки',
  //   type: 'sub',
  //   icon: 'link',
  //   permissions: [],
  //   children: [
  //     {
  //       state: AppRoutesFull.main.externalLinks.list,
  //       name: 'Все внешние ссылки',
  //       type: 'link',
  //       permissions: [],
  //     },
  //     {
  //       state: AppRoutesFull.main.externalLinks.add,
  //       name: 'Добавить вн. ссылку',
  //       type: 'link',
  //       permissions: [],
  //     }, // Добавить внешнюю ссылку
  //   ]
  // },
];
