import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
// models
import {RecoveryStartRoute} from '@onlineShop/models';
// constants
import {AppRoutesFull} from '@onlineShop/constants';
// rxjs
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecoveryVerifyGuard implements CanActivate {
  constructor(private _router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const recoveryStartRoute = this._router.getCurrentNavigation().extras.state as RecoveryStartRoute;
    if (recoveryStartRoute &&
      recoveryStartRoute.token &&
      recoveryStartRoute.username &&
      recoveryStartRoute.type &&
      recoveryStartRoute.date) {
      return of(true);
    } else {
      this._router.navigate([AppRoutesFull.auth.recovery.start]).then();
      return of(false);
    }
  }
}
