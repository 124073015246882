<ng-container *ngIf="data.status !== -1">
  <h2 mat-dialog-title>Изменение состояние заказа <b>#{{data.itemName}}</b></h2>
  <mat-dialog-content class="mat-typography">
    Вы действительно хотите изменить состояние заказа <b class="text-primary">#{{data.itemName}}</b> на
    <span [style.color]="getOrderStatus(data.status).color"
          style="white-space: nowrap;"><b>{{getOrderStatus(data.status).name}}</b></span>?
  </mat-dialog-content>
  <div [style.box-sizing]="'content-box'" align="end" mat-dialog-actions>
    <button [mat-dialog-close]="true" [disabled]="disabledBtn" mat-button color="primary">
      Изменить{{disabledBtn ? ' (' + timer + ')' : ''}}</button>
    <button mat-button mat-dialog-close mat-flat-button>Отменить</button>
  </div>
</ng-container>
<ng-container *ngIf="data.status === -1">
  <h2 mat-dialog-title>Отменить заказ <b>#{{data.itemName}}</b></h2>
  <mat-dialog-content class="mat-typography">
    Вы действительно хотите отменить заказ <b class="text-primary">#{{data.itemName}}</b>?
  </mat-dialog-content>
  <div [style.box-sizing]="'content-box'" align="end" mat-dialog-actions>
    <button [mat-dialog-close]="true" [disabled]="disabledBtn" mat-button color="warn">Отменить
      заказ{{disabledBtn ? ' (' + timer + ')' : ''}}</button>
    <button mat-button mat-dialog-close mat-flat-button>Отменить</button>
  </div>
</ng-container>

