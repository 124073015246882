import {Directive, HostListener, Input, OnDestroy} from '@angular/core';
import {NgControl} from '@angular/forms';
// rxjs
import {BehaviorSubject} from 'rxjs';

@Directive({
  selector: 'input[inputOnlyNumberFloat]'
})
export class InputOnlyNumberFloatDirective implements OnDestroy {
  @Input() min: number;
  @Input() max: number;
  @Input() lengthAfterDot = 2;
  private _el: NgControl;
  private _lastNumber$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private ngControl: NgControl) {
    this._el = ngControl;
  }

  @HostListener('input', ['$event.target.value']) onInput(value: string): void {
    try {
      let numb: string | number =
        value.replace(',', '.')
          .replace(/[^0-9.]/g, '')
          .replace(/\./, 'x')
          .replace(/\./g, '')
          .replace(/x/, '.').toString();

      if (numb !== '') {
        if (numb[numb.length - 1] !== '.') {
          if (numb[0] === '.') {
            numb = `0${numb}`;
          }

          const dotIndex = numb.indexOf('.');
          const lastIndex = numb.length - 1;
          if (dotIndex !== -1 && lastIndex - dotIndex > this.lengthAfterDot) {
            numb = numb.substring(0, dotIndex + this.lengthAfterDot + 1);
          }

          numb = Number(numb);

          if (this.min) {
            if (this.min > numb) {
              const minLength = this.min.toString().length;
              const maxLength = this.max.toString().length;
              const numberLength = numb.toString().length;
              const min = Math.trunc(this.min / Math.pow(10, (minLength - numberLength)));
              const max = Math.trunc(this.max / Math.pow(10, (maxLength - numberLength)));
              if (min > numb || max < numb) {
                numb = this._lastNumber$.value;
              }
            }
          }
          if (this.max) {
            if (this.max < numb) {
              numb = this._lastNumber$.value;
            }
          }
        }
        this._lastNumber$.next(numb.toString());
        this._el.control.patchValue(numb.toString());
      } else {
        this._lastNumber$.next(null);
        this._el.control.patchValue(null);
      }
    } catch (error) {
      this._el.control.patchValue(null);
    }
  }

  ngOnDestroy(): void {
    this._lastNumber$.next(null);
    this._lastNumber$.complete();
  }
}
