import {AbstractControl, ValidationErrors} from '@angular/forms';
// libraries
import {CountryCode, parsePhoneNumberFromString} from 'libphonenumber-js';
// constants
import {
  Age,
  HasAlphabet,
  HasNumber,
  InputLength,
  NotAlphabetNumber,
  NotNumber,
  NotPhone,
  OnlyAllAlphabet,
  ValidEmail
} from '@onlineShop/constants';

type ValidatorFn = (control: AbstractControl) => ValidationErrors | null;

export function UsernameValidation(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let error: ValidationErrors = null;
    const value = control.value;

    if (value) {
      const regexPhone = value.replace(NotPhone, '').toString();
      if (regexPhone === value) {
        if (value.toString().length > 1 && value.toString()[1] === '+') {
          error = {'usernameInvalid': true};
        } else {
          let phone = value.toString();
          if (phone[0] !== '+') {
            phone = `+${phone}`;
          }
          const phoneNumber = parsePhoneNumberFromString(phone);
          error = (!phoneNumber || !phoneNumber.isValid()) ? {'usernameInvalid': true} : null;
        }
      } else {
        const test = ValidEmail.exec(value);
        let valid = false;

        if (test !== null) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < test.length; i++) {
            if (test[i] === test.input) {
              valid = true;
            }
          }
        }

        error = valid ? null : {'usernameInvalid': true};
      }
    }

    return error;
  };
}

export function PhoneValidation(country: CountryCode = null): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let error: ValidationErrors = null;
    const value = control.value;

    if (value) {
      const phoneNumber = country ? parsePhoneNumberFromString(value, country) : parsePhoneNumberFromString(value);
      error = (!phoneNumber || !phoneNumber.isValid()) ? {'phoneNumberInvalid': true} : null;
    }

    return error;
  };
}

export function VerifyCodeValidation(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let error: ValidationErrors = null;
    const value = control.value;

    if (value) {
      const numb: string = control.value.replace(NotNumber, '').toString();
      error = (value !== numb || numb.length !== InputLength.verifyCode.length) ? {'verifyCodeInvalid': true} : null;
    }

    return error;
  };
}

export function PostCodeValidation(regex: RegExp = new RegExp('\\d{6}')): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let error: ValidationErrors = null;
    const value = control.value;

    if (value) {
      let valid = false;
      const test = regex.exec(value);

      if (test !== null) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < test.length; i++) {
          if (test[i] === test.input) {
            valid = true;
          }
        }
      }

      error = valid ? null : {'postCodeInValid': true};
    }

    return error;
  };
}

export function FirstNameValidation(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let error: ValidationErrors = null;
    const value = control.value;

    if (value) {
      error = value.toString().length >= InputLength.firstName.minLength ? null : {'nameMinLength': true};

      if (!error) {
        error = value.toString().length <= InputLength.firstName.maxLength ? null : {'nameMaxLength': true};
      }

      if (!error) {
        error = OnlyAllAlphabet.test(value) ? null : {'notIsAlphabet': true};
      }
    }
    return error;
  };
}

export function DateOfBirthValidation(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let error: ValidationErrors = null;
    const touched = control.touched;

    if (touched) {
      const day: number = control.get('day').value;
      const month: number = control.get('month').value;
      const year: number = control.get('year').value;

      if (day && month && year) {
        let errorDay: ValidationErrors;
        let errorMonth: ValidationErrors;
        let errorYear: ValidationErrors;

        errorDay = InputLength.dateOfBirth.day.min <= day
        && InputLength.dateOfBirth.day.max >= day ? null : {'dateOfBirthNotCorrect': true};

        errorMonth = InputLength.dateOfBirth.month.min <= month
        && InputLength.dateOfBirth.month.max >= month ? null : {'dateOfBirthNotCorrect': true};

        errorYear = InputLength.dateOfBirth.year.min <= year
        && InputLength.dateOfBirth.year.max >= year ? null : {'dateOfBirthNotCorrect': true};

        error = !errorDay && !errorMonth && !errorYear ? null : {'dateOfBirthNotCorrect': true};

        if (!error) {
          error = IsDateOfBirthCorrect(day, month, year) ? null : {'dateOfBirthNotCorrect': true};
          errorDay = errorMonth = errorYear = error;
        }

        if (!error) {
          error = GetAge(day, month, year) > Age.min ? null : {'dateOfBirthMinimized': true};
          errorDay = errorMonth = errorYear = error;
        }

        if (!error) {
          error = GetAge(day, month, year) < Age.max ? null : {'dateOfBirthMaximized': true};
          errorDay = errorMonth = errorYear = error;
        }

        control.get('day').setErrors(errorDay);
        control.get('month').setErrors(errorMonth);
        control.get('year').setErrors(errorYear);
      }
    }
    return error;
  };
}

export function PasswordValidation(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let error: ValidationErrors = null;
    const value = control.value;

    if (value) {
      error = IsPasswordSpecialSymbol(value.toString()) ? null : {'passwordSpecialSymbol': true};

      if (!error) {
        error = value.toString().length >= InputLength.password.minLength ? null : {'passwordMinLength': true};
      }

      if (!error) {
        error = value.toString().length <= InputLength.password.maxLength ? null : {'passwordMaxLength': true};
      }

      if (!error) {
        const hasNumber = HasNumber.test(value.toString());
        const hasAlphabet = HasAlphabet.test(value.toString());
        error = hasNumber && hasAlphabet ? null : {'passwordInCorrect': true};
      }
    }
    return error;
  };
}

export function PasswordMatchValidator(newPassword: boolean = false): (control: AbstractControl) => void {
  return (control: AbstractControl) => {
    if (newPassword === true) {
      const newPasswordInput: string = control.get('newPassword').value;
      const verifyNewPassword: string = control.get('verifyNewPassword').value;
      if (newPasswordInput && verifyNewPassword && newPasswordInput !== verifyNewPassword) {
        control.get('verifyNewPassword').setErrors({'noPasswordMatch': true});
      }
    } else {
      const password: string = control.get('password').value;
      const verifyPassword: string = control.get('verifyPassword').value;
      if (password && verifyPassword && password !== verifyPassword) {
        control.get('verifyPassword').setErrors({'noPasswordMatch': true});
      }
    }
  };
}

function IsPasswordSpecialSymbol(value): boolean {
  const regex = value.replace(NotAlphabetNumber, '').toString();
  return value === regex;
}

function IsDateOfBirthCorrect(day: number, month: number, year: number): boolean {
  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }
  return day <= monthLength[month - 1];
}

function GetAge(day: number, month: number, year: number): number {
  const birth = new Date(`${year}/${month}/${day}`);
  const now = new Date();

  let age = now.getFullYear() - birth.getFullYear();
  const m = now.getMonth() - birth.getMonth();

  if (m < 0 || (m === 0 && now.getDate() < birth.getDate())) {
    age--;
  }
  return age;
}
