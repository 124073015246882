<form (ngSubmit)="onSubmitClick()" [formGroup]="formGroup" autocomplete="off">
  <h2 mat-dialog-title>{{item.title ? item.title : 'Адрес'}}</h2>
  <mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="space-between none" class="m-t-1">
      <!-- Message Error -->
      <div *ngIf="errorMessage || (error && error.length > 0)" class="m-b-20" fxFlex="100">
        <mat-error class="m-0 p-0 message-error">{{errorMessage}}</mat-error>
        <mat-error *ngFor="let err of error" class="m-0 p-0 message-error font-12">
          <div *ngIf="isArray(err)">
            <mat-error *ngFor="let e of err" class="m-0 p-0 message-error font-12">
              {{e}}
            </mat-error>
          </div>
          <div *ngIf="isArray(err) === false">
            {{err}}
          </div>
        </mat-error>
      </div>

      <!-- Order Item -->
      <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between none">
        <!-- Product -->
        <div [fxHide]="item.type !== 'add'" fxFlex="100" fxFlex.gt-xs="100">
          <mat-form-field appearance="outline">
            <mat-label>Товар</mat-label>
            <mat-select
              #selectProduct
              (selectionChange)="changeProduct($event)"
              (openedChange)="closeProduct($event)"
              [compareWith]="compareProductObjects"
              formControlName="product"
              panelClass="select-option-image"
              required>
              <mat-select-trigger>
                <div *ngIf="assertProductType(formGroup.get('product').value) as product" class="select-trigger-image">
                  <img *ngIf="product?.images?.length"
                       [src]="getImage(product.images[0], 1, 'products')"
                       alt="">
                  {{product.name}} ({{product.price}} с.) ({{product.quantity}} {{product.unit.name}}.)
                </div>
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search [searching]="loadingProductFilter"
                                       [clearSearchInput]="false"
                                       formControlName="productFilter"
                                       placeholderLabel="Поиск..."
                                       ariaLabel="Поиск..."></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let product of responseProduct.data"
                          [disabled]="!product.active || product.quantity === 0 || issetProductInOrderItems(product.id)"
                          [value]="product">
                <div class="option-text-with-image">
                  <img *ngIf="product?.images?.length"
                       [src]="getImage(product.images[0], 1, 'products')"
                       alt="">
                  {{product.name}} ({{product.price}} с.) ({{product.quantity}} {{product.unit.name}}.)
                </div>
              </mat-option>
              <mat-option
                [value]="null"
                [class.d-none]="responseProduct.pagination.total === responseProduct.pagination.count"
                (click)="selectProduct.open()"
                class="height-auto">
                <mat-paginator
                  #paginatorProduct
                  (page)="changePageProduct($event)"
                  [length]="responseProduct.pagination.total"
                  [pageIndex]="responseProduct.pagination.currentPage - 1"
                  [pageSize]="responseProduct.pagination.perPage"
                  [showFirstLastButtons]="true"></mat-paginator>
              </mat-option>
            </mat-select>
            <mat-error
              *ngIf="formGroup.get('product').hasError('required') &&
                     formGroup.get('product').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Укажите товар</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Product Info -->
        <div *ngIf="product?.id" fxFlex="100" fxFlex.gt-xs="100" class="font-14 m-b-10">
          <div class="m-b-5"><b>Товар:</b> {{product.name}}</div>
          <div class="m-b-5"><b>Цена товара:</b> {{product.price}} с.</div>
          <div class="m-b-5"><b>Остаток:</b> {{product.quantity}} {{product.unit.name}}.</div>
        </div>

        <!-- Quantity -->
        <div fxFlex="100" fxFlex.gt-xs="48">
          <mat-form-field appearance="outline">
            <mat-label>Количество</mat-label>
            <input [max]="this.product?.quantity || 1"
                   [lengthAfterDot]="4"
                   [step]="this.product?.unitStep || 1"
                   formControlName="quantity"
                   inputOnlyNumberFloat
                   matInput
                   placeholder="Количество"
                   type="number"
                   required>
            <mat-error
              *ngIf="formGroup.get('quantity').hasError('required') &&
                     formGroup.get('quantity').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Укажите количество товара</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Price -->
        <div fxFlex="100" fxFlex.gt-xs="48">
          <mat-form-field appearance="outline">
            <mat-label>Цена</mat-label>
            <input formControlName="price"
                   inputOnlyNumberFloat
                   matInput
                   placeholder="Цена"
                   required>
            <mat-error
              *ngIf="formGroup.get('price').hasError('required') &&
                     formGroup.get('price').touched"
              fxLayout="row wrap"
            >
              <mat-icon fxFlex="24px">error</mat-icon>
              <span fxFlex="calc(100% - 24px)">Укажите цену</span>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Quantity And Price Info -->
        <div *ngIf="product?.id" fxFlex="100" fxFlex.gt-xs="100" class="font-14">
          <div class="m-b-5">
            <b>Сумма:</b> {{(formGroup.get('price').value !== null ? formGroup.get('price').value : product.price) *
          (formGroup.get('quantity').value || product.unitStep) | number:'1.0-4' | removeComma}} с.
          </div>
          <div *ngIf="product.convStep && product.convUnit" class="m-b-5">
            <b>Конвертация :</b>
            {{formGroup.get('quantity').value || product.unitStep}} {{product.unit.name}}. =
            {{product.convStep *
          (formGroup.get('quantity').value || product.unitStep) | number:'1.0-4' | removeComma}}
            {{product.convUnit.name}}.
          </div>
          <div *ngIf="product.unitStep !== 1" class="m-b-5">
            <b>Минимальный заказ:</b> {{product.unitStep}} {{product.unit.name}}.
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div [style.box-sizing]="'content-box'" align="end" mat-dialog-actions>
    <input #closeDialog [mat-dialog-close]="order" type="hidden">
    <button mat-button mat-dialog-close mat-flat-button type="button">Отменить</button>
    <button mat-button type="submit" color="primary">{{item.textButton}}</button>
  </div>
</form>
