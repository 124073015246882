import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
// services
import {BaseService} from './base.service';
import {AddIconSvgService} from './add-icon-svg.service';
// models
import {Country, HttpResponseAPI} from '@onlineShop/models';
// functions
import {getQueryParams} from '@onlineShop/functions';
// rxjs
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends BaseService<Country> {
  protected url = 'client/general/info/countries';
  private _url = 'cp/countries';

  constructor(
    protected _http: HttpClient,
    protected _addSvgIcon: AddIconSvgService
  ) {
    super(_http);
  }

  // get countries
  getCountries(params: any = {}): Observable<HttpResponseAPI> {
    return this._http.get<HttpResponseAPI>(this._url, getQueryParams(params))
      .pipe(
        map((result: HttpResponseAPI) => {
          result.data = result.data as Country[];
          return result;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  // update supplier country
  updateCountryActive(countryId: number, active: boolean): Observable<boolean> {
    const body = {active, _method: 'PUT'};

    return this._http.post<HttpResponseAPI>(`${this._url}/${countryId}/update-active`, body)
      .pipe(
        map((result: HttpResponseAPI) => {
          return result.data as boolean;
        }),
        catchError((err: HttpErrorResponse) => throwError(err))
      );
  }

  protected normalize = (item: any): Country => ({
    id: item.id,
    countryCode: item.countryCode,
    name: item.name,
    postCodePattern: item.postCodePattern,
    phoneCode: item.phoneCode,
    phoneMaxLength: item.phoneMaxLength,
    flag: this._addSvgIcon.add(item.flag, 'FLAG-', item.countryCode)
  } as Country)
}

