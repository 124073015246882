import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
// services
import {AuthService} from '@onlineShop/services/auth.service';
// constants
import {AppRoutesFull} from '@onlineShop/constants';
// rxjs
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private _authService: AuthService,
    private _router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this._authService.isLoggedIn()
      .pipe(
        map((res: boolean) => {
          if (!res) {
            this._router.navigateByUrl(AppRoutesFull.auth.login).then(() => this._authService.logout());
          }
          return res;
        }),
        catchError(() => {
          this._router.navigateByUrl(AppRoutesFull.auth.login).then(() => this._authService.logout());
          return of(false);
        })
      );
  }
}
