import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrls: ['./quill-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class QuillEditorComponent {
  @Input() formGroupEditor: FormGroup;
  @Input() frCtrlEditorName: string;
  @Input() isRequired = false;
  @Input() label: string;
}
