import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
// environments
import {environment} from '../../../environments/environment';
// services
import {LocalStorageService} from 'ngx-webstorage';
// models
import {ImageThumbnail, OptionCookie} from '@onlineShop/models';
// rxjs
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ConfigService {

  // dark mode subject
  private _darkModeSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  readonly darkMode$: Observable<boolean> = this._darkModeSubject$.asObservable();

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _localStorageService: LocalStorageService,
  ) {
    this.setDarkMode(Boolean(this.darkMode));
  }

  // Recaptcha Site Key
  private static _recaptchaSiteKey = environment.recaptchaSiteKey;

  static get recaptchaSiteKey(): string {
    return this._recaptchaSiteKey;
  }

  // API Url
  private _apiUrl = environment.apiUrl;

  get apiUrl(): string {
    return this._apiUrl;
  }

  // prod mode
  private _production = environment.production;

  get production(): boolean {
    return this._production;
  }

  // get dark mode from cookie
  get darkMode(): boolean {
    return JSON.parse(this._localStorageService.retrieve('DARK_MODE'));
  }

  // counter register verify time
  private _counterRegisterVerifyTime = 300;

  get counterRegisterVerifyTime(): number {
    return this._counterRegisterVerifyTime;
  }

  // counter register verify code time
  private _counterRegisterVerifyCodeTime = 60;

  get counterRegisterVerifyCodeTime(): number {
    return this._counterRegisterVerifyCodeTime;
  }

// Image Thumbnail
  private _imageThumbnail: ImageThumbnail = {
    products: {
      1: {
        name: '_90x90',
        width: 90,
        height: 90,
        default: false
      },
      2: {
        name: '_150x150',
        width: 150,
        height: 150,
        default: false
      },
      3: {
        name: '_285x285',
        width: 285,
        height: 285,
        default: false
      },
      4: {
        name: '_700x700',
        width: 700,
        height: 700,
        default: false
      },
      5: {
        name: '_1024x1024',
        width: 1024,
        height: 1024,
        default: true
      },
    },
    users: {
      1: {
        name: '_60x60',
        width: 60,
        height: 60,
        default: false
      },
      2: {
        name: '_160x160',
        width: 160,
        height: 160,
        default: true
      },
      3: {
        name: '_300x300',
        width: 300,
        height: 300,
        default: true
      },
    },
    manufacturers: {
      1: {
        name: '_60x60',
        width: 60,
        height: 60,
        default: false
      },
      2: {
        name: '_160x160',
        width: 160,
        height: 160,
        default: true
      },
      3: {
        name: '_300x300',
        width: 300,
        height: 300,
        default: true
      },
    },
    suppliers: {
      1: {
        name: '_60x60',
        width: 60,
        height: 60,
        default: false
      },
      2: {
        name: '_160x160',
        width: 160,
        height: 160,
        default: true
      },
      3: {
        name: '_300x300',
        width: 300,
        height: 300,
        default: true
      },
    },
  };

  get imageThumbnail(): ImageThumbnail {
    return this._imageThumbnail;
  }

  // Option Cookie
  private _optionCookie: OptionCookie = {
    expires: 0,
    path: '/',
    domain: this.production ? '.tozon.tj' : 'localhost',
    secure: true,
    sameSite: 'Strict',
  };

  get optionCookie(): OptionCookie {
    return this._optionCookie;
  }

  // change expires cookie
  expiresCookie(value: number | Date): void {
    this._optionCookie.expires = value;
  }

  // set dark mode
  setDarkMode(darkMode: boolean): void {
    this._document.body.classList.toggle('dark', darkMode);
    this._localStorageService.store('DARK_MODE', JSON.stringify(darkMode));
    this._darkModeSubject$.next(darkMode);
  }


  IsIOS(): boolean {
    const iOS1to12 = /iPad|iPhone|iPod/.test(navigator.platform);

    const iOS13iPad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

    const iOS1to12quirk = () => {
      const audio = new Audio(); // temporary Audio object
      audio.volume = 0.5; // has no effect on iOS <= 12
      return audio.volume === 1;
    };

    return !window.MSStream && (iOS1to12 || iOS13iPad || iOS1to12quirk());
  }

  IsAndroid(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
  }

  IsMobile(): boolean {
    return this.IsIOS() || this.IsAndroid();
  }

  IsMobileApp(): boolean {
    const standalone = window.navigator['standalone'];
    const userAgent = window.navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const webview = /; wv/.test(userAgent);

    if (this.IsIOS()) {
      if (!standalone && safari) {
        // Safari
        return false;
      } else if (!standalone && !safari) {
        // iOS webview
        return true;
      }
    } else if (this.IsAndroid()) {
      return webview;
    } else {
      return false;
    }
  }
}
