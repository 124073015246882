import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
// angular material
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
// services
import {ToastrService} from 'ngx-toastr';
import {ConfigService} from '@onlineShop/services/config.service';
import {OrderService} from '@onlineShop/services/order.service';
import {ProductService} from '@onlineShop/services/product.service';
// models
import {
  Address,
  City,
  Country,
  CreateOrderItemRequest,
  CustomFields,
  HttpResponseAPI,
  Order,
  OrderItem,
  Pagination,
  Product,
  Region,
  UpdateOrderItemRequest
} from '@onlineShop/models';
// constants
import {InputLength} from '@onlineShop/constants';
// rxjs
import {catchError, debounceTime, filter, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {of, ReplaySubject} from 'rxjs';

interface ResponseProduct extends HttpResponseAPI {
  data: Product[];
  pagination: Pagination;
}

interface ItemData extends OrderItem {
  counter: number;
}

interface ItemAddress extends Address {
  country: Country;
  region: Region;
  city: City;
}

interface OrderWithItems extends Order {
  items: ItemData[];
  address: ItemAddress;
}

interface Item extends ItemData {
  title: string;
  type: 'add' | 'edit';
  textButton: string;
  order: OrderWithItems;
}

@Component({
  selector: 'app-address-form-modal',
  templateUrl: 'order-item-form-modal.component.html',
  styleUrls: ['./order-item-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderItemFormModalComponent implements OnInit, OnDestroy {
  @ViewChild('closeDialog') closeDialog: ElementRef;
  @ViewChild('paginatorProduct', {static: true}) paginatorProduct: MatPaginator = Object.create(null);

  formGroup: FormGroup;
  errorMessage: string;
  error: string[] = [];
  loading = false;

  // input lengths
  inpLn = InputLength;
  // order
  order: OrderWithItems;
  // product
  product: Product;
  // product filter
  responseProductEmpty: ResponseProduct = {
    data: [],
    pagination: {
      count: 0,
      currentPage: 0,
      perPage: 0,
      total: 0,
      totalPages: 0
    }
  };
  responseProduct = this.responseProductEmpty;
  responseProductPrev = this.responseProduct;
  pageEventProduct: PageEvent;
  valueProductFilter: string;
  loadingProductFilter = false;

  private _destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public item: Item,
    private _fb: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private _toastrService: ToastrService,
    private _orderService: OrderService,
    private _productService: ProductService,
    private _configService: ConfigService,
  ) {
    if (item && item.order && item.order.id) {
      this.order = item.order;
    }
  }

  private static translateMatPaginator(paginator: MatPaginator): void {
    paginator._intl.itemsPerPageLabel = 'Товаров на странице: ';
    paginator._intl.firstPageLabel = 'Первая страница';
    paginator._intl.lastPageLabel = 'Последняя страница';
    paginator._intl.nextPageLabel = 'Следущая страница';
    paginator._intl.previousPageLabel = 'Предыдущая страница';
    paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return '0 из ' + length;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' из ' + length;
    };
  }

  ngOnInit(): void {
    OrderItemFormModalComponent.translateMatPaginator(this.paginatorProduct);

    this.formGroup = this._fb.group({
        product: [null, [Validators.required]],
        quantity: [null, [Validators.required]],
        price: [null, [Validators.required]],
        productFilter: [null],
      },
    );

    if (this.item.type === 'add') {
      this.formGroup.get('productFilter').valueChanges
        .pipe(
          map(filtered => filtered && filtered.trim() ? filtered.trim() : null),
          tap(() => this.responseProduct = this.responseProductPrev),
          filter(filtered => filtered !== this.valueProductFilter ||
            this.responseProduct?.pagination?.currentPage !== (this.pageEventProduct &&
            (this.responseProduct?.pagination?.currentPage !== this.pageEventProduct.pageIndex + 1) &&
            filtered === this.valueProductFilter ? this.pageEventProduct.pageIndex + 1 : 1)),
          debounceTime(200),
          map((filtered: string) => {
            const params: CustomFields = {};
            params.page = this.pageEventProduct && (this.responseProduct?.pagination?.currentPage !==
              this.pageEventProduct.pageIndex + 1) && filtered === this.valueProductFilter ?
              this.pageEventProduct.pageIndex + 1 : 1;
            params.limit = 15;
            params.sort = 'name';
            params.direction = 'asc';
            if (filtered) {
              params.filter = filtered;
            }
            return params;
          }),
          switchMap((params: CustomFields) => {
            this.loadingProductFilter = true;
            this._cdr.markForCheck();

            const filtered = params?.filter ? params.filter : null;
            if (filtered === this.valueProductFilter &&
              this.responseProduct?.pagination?.currentPage === params?.page) {
              this.valueProductFilter = filtered;
              return of(this.responseProductPrev);
            }

            this.valueProductFilter = filtered;

            return this._productService.getProducts(params)
              .pipe(
                map(items => items as ResponseProduct),
                catchError((err: ResponseProduct) => of(err)),
                takeUntil(this._destroy$)
              );
          }),
          map(response => response ? response : this.responseProductEmpty),
          takeUntil(this._destroy$),
        )
        .subscribe(response => {
            if ((response?.status !== 200 && response.message) || response.error) {
              if (response && response.message !== null && response.message !== undefined) {
                this.errorMessage = response.message;
                this.error = response.error ? Object.values(response.error) : [];
              } else {
                this.errorMessage = `Ошибка ${(response.status ? ` - ${response.status}` : '')}`;
                this.error = [];
              }
              this.responseProduct = this.responseProductEmpty;
              this.responseProductPrev = this.responseProduct;
            } else {
              this.responseProduct = response;
              this.responseProductPrev = this.responseProduct;
              this.errorMessage = null;
              this.error = [];
            }
            this.loadingProductFilter = false;
            this._cdr.markForCheck();
          },
          () => {
            this.responseProduct = this.responseProductEmpty;
            this.responseProductPrev = this.responseProduct;
            this.errorMessage = 'Неизвестная ошибка';
            this.error = [];
            this.loadingProductFilter = false;
            this._cdr.markForCheck();
          });
    }

    this.formGroup.get('product').valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe(value => {
        if (!value && this.product) {
          this.formGroup.get('product').setValue(this.product);
        } else {
          this.product = value;
        }

        if (this.product?.id) {
          this.formGroup.get('quantity').enable();
          this.formGroup.get('price').enable();
        } else {
          this.formGroup.get('quantity').disable();
          this.formGroup.get('price').disable();
        }
      });

    if (this.item.id && this.item.type === 'edit') {
      this.formGroup.get('product').setValue(this.item?.product ? this.item.product : null);
      this.formGroup.get('quantity').setValue(this.item?.quantity ? this.item.quantity : null);
      this.formGroup.get('price').setValue(this.item?.price !== undefined ? this.item.price : null);
      this.formGroup.get('productFilter').setValue(this.item?.product?.name ? this.item.product.name : null);
      this._cdr.markForCheck();
    } else {
      this.formGroup.get('product').setValue(null);
      this.formGroup.get('productFilter').setValue(null);
    }
  }

  compareProductObjects(object1: Product, object2: Product): boolean {
    return object1 && object2 && object1.id === object2.id;
  }

  changeProduct(event: MatSelectChange): void {
    if (event?.value) {
      const product = event.value as Product;

      if (product?.id) {
        this.formGroup.get('product').setValue(product);
        this.formGroup.get('quantity').setValue(product.quantity && product.unitStep ? product.unitStep : 0);
        this.formGroup.get('price').setValue(product.price);
        this._cdr.markForCheck();
      }
    }
  }

  closeProduct(opened: boolean): void {
    if (opened === false && this.product?.id) {
      if (this.responseProduct.data.length) {
        const showing = this.responseProduct.data.filter(product => product.id === this.product.id);
        if (!showing.length) {
          this.formGroup.get('productFilter').setValue(this.product?.name);
        }
      } else {
        this.formGroup.get('productFilter').setValue(this.product?.name);
      }
    }
  }

  issetProductInOrderItems(productId: number): boolean {
    if (this.item.order.items.length) {
      return !!(this.item.order.items.filter(item => item.product.id === productId).length);
    }
    return false;
  }

  changePageProduct(event: PageEvent): void {
    this.pageEventProduct = event;
    this.formGroup.get('productFilter').setValue(this.formGroup.get('productFilter').value);
  }

  onSubmitClick(): void {
    this.formGroup.markAllAsTouched();
    const product = this.formGroup.get('product').value as Product;

    if (this.formGroup.valid && this.formGroup.enabled && product?.id) {
      this.loading = true;
      this.formGroup.disable();
      this.errorMessage = null;
      this.error = [];

      const productId = product.id;
      const quantity = this.formGroup.get('quantity').value;
      const price = this.formGroup.get('price').value;

      if (this.item.type === 'add') {
        const createOrderItemRequest: CreateOrderItemRequest = {
          orderId: this.item?.orderId ? this.item?.orderId : null,
          productId,
          quantity,
          price,
        };

        this._orderService.createItemInOrder(createOrderItemRequest)
          .pipe(
            map(order => {
              if (order?.items.length) {
                let counter = 0;
                order.items = order.items.map(i => {
                  counter++;
                  return {...i, counter};
                });
              }
              return order;
            }),
            takeUntil(this._destroy$)
          )
          .subscribe((result: OrderWithItems) => {
              if (result && result.id) {
                this.order = result;
                this._cdr.detectChanges();
                this.closeDialog.nativeElement.click();
                this.loading = false;
                this.formGroup.enable();
                this.toastr('Товар заказа успешно добавлен.', 'success');
              } else {
                this.errorMessage = 'Неизвестная ошибка';
                this.toastr(`Ошибка при добавлении товар заказа.`, 'error');
                this.loading = false;
                this.formGroup.enable();
                this._cdr.markForCheck();
              }
            },
            (res: HttpErrorResponse) => {
              if (res && res.message !== null && res.message !== undefined) {
                this.errorMessage = res.message;
                this.error = res.error ? Object.values(res.error) : [];
              } else {
                this.errorMessage = `Ошибка${(res.status ? ` - ${res.status}` : '')}`;
                this.error = [];
              }
              this._cdr.markForCheck();
              this.loading = false;
              this.toastr(`Ошибка при добавлении товар заказа.`, 'error');
              this.formGroup.enable();
              this._cdr.markForCheck();
            }
          );
      } else if (this.item.type === 'edit') {
        const updateOrderItemRequest: UpdateOrderItemRequest = {
          id: this.item?.id ? this.item.id : null,
          orderId: this.item?.orderId ? this.item?.orderId : null,
          quantity,
          price,
        };

        this._orderService.updateItemInOrder(updateOrderItemRequest)
          .pipe(
            map(order => {
              if (order?.items.length) {
                let counter = 0;
                order.items = order.items.map(i => {
                  counter++;
                  return {...i, counter};
                });
              }
              return order;
            }),
            takeUntil(this._destroy$)
          )
          .subscribe((result: OrderWithItems) => {
              if (result && result.id) {
                this.order = result;
                this._cdr.detectChanges();
                this.closeDialog.nativeElement.click();
                this.loading = false;
                this.formGroup.enable();
                this.toastr('Товар заказа успешно изменён.', 'success');
              } else {
                this.errorMessage = 'Неизвестная ошибка';
                this.toastr(`Ошибка при изменении товар заказа.`, 'error');
                this.loading = false;
                this.formGroup.enable();
                this._cdr.markForCheck();
              }
            },
            (res: HttpErrorResponse) => {
              if (res && res.message !== null && res.message !== undefined) {
                this.errorMessage = res.message;
                this.error = res.error ? Object.values(res.error) : [];
              } else {
                this.errorMessage = `Ошибка${(res.status ? ` - ${res.status}` : '')}`;
                this.error = [];
              }
              this._cdr.markForCheck();
              this.loading = false;
              this.toastr(`Ошибка при изменении товар заказа.`, 'error');
              this.formGroup.enable();
              this._cdr.markForCheck();
            }
          );
      }
    }
  }

  getImage(image: string, size: number = 2, type: string = 'products'): string {
    if (image && image.length) {
      const index = image.lastIndexOf('.');
      if (index !== -1) {
        image = image.substring(0, index) +
          this._configService.imageThumbnail[type][size].name +
          image.substring(index, image.length);
      }
    }
    return image;
  }

  isArray(obj: any): boolean {
    return Array.isArray(obj);
  }

  assertProductType = (product: Product): Product => product;

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  private toastr(message: string, type: 'success' | 'error' | 'info' | 'warning' = 'success'): void {
    const override = {timeOut: 2000, progressBar: true};
    if (type === 'success') {
      this._toastrService.success(message, null, override);
    } else if (type === 'error') {
      this._toastrService.error(message, null, override);
    } else if (type === 'info') {
      this._toastrService.info(message, null, override);
    } else if (type === 'warning') {
      this._toastrService.warning(message, null, override);
    }
  }
}
