import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
// models
import {RegisterVerifyRoute} from '@onlineShop/models';
// constants
import {AppRoutesFull} from '@onlineShop/constants';
// rxjs
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RegisterFinishGuard implements CanActivate {
  constructor(private _router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const registerVerifyRoute = this._router.getCurrentNavigation().extras.state as RegisterVerifyRoute;
    if (registerVerifyRoute &&
      registerVerifyRoute.token &&
      registerVerifyRoute.username &&
      registerVerifyRoute.type
    ) {
      return of(true);
    } else {
      this._router.navigate([AppRoutesFull.auth.register.start]).then();
      return of(false);
    }
  }
}
