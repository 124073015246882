<h2 mat-dialog-title>Заказ {{'#' + item.name}}</h2>
<mat-dialog-content>
  <div *ngIf="item && item.id" fxLayout="column">
    <div fxLayout="column" fxLayout.gt-md="row" class="m-t-15 m-b-15">
      <div fxFlex.gt-md="50" fxFlex="100">
        <span class="font-medium">Состояние заказа:</span>
        <h6 [style.color]="getOrderStatus(item.status).color"
            class="m-t-5 m-b-0 font-weight-400">{{getOrderStatus(item.status).name}}</h6>
      </div>
      <div fxFlex.gt-md="50" fxFlex="100" class="text-right order-header">
        <span class="font-medium">Дата создания заказа:</span>
        <h6 class="m-t-5 m-b-10">{{item.createdAt}}</h6>
        <span class="font-medium">Дата изменение заказа:</span>
        <h6 class="m-t-5 m-b-0">{{item.updatedAt}}</h6>
      </div>
    </div>
    <hr/>

    <div fxLayout="column" fxLayout.gt-md="row" class="m-t-15 m-b-15">
      <div fxFlex.gt-md="50" fxFlex="100">
        <h4 class="m-0 m-b-10 font-medium">Клиент</h4>
        <div (click)="openUserModal(item.user)" class="custom-item">
          <img [src]="getImage(item.user.image)" class="image-client" alt="" draggable="false">
          {{item.user.firstName}}<br>
          {{item.user.lastName}}<br>
          {{item.user.phone}}
        </div>
      </div>
      <div fxFlex.gt-md="50" fxFlex="100" class="text-right order-header">
        <h4 class="m-0 m-b-10 font-medium">Адрес доставки</h4>
        <h6 class="m-t-0 m-b-5">{{item.address.country.name}}</h6>
        <h6 class="m-t-0 m-b-5">{{item.address.region.name}}</h6>
        <h6 class="m-t-0 m-b-5">{{item.address.city.name}}</h6>
        <h6 class="m-t-0 m-b-5">{{item.address.addressLine1}}</h6>
        <h6 class="m-t-0 m-b-5">{{item.address.addressLine2}}</h6>
      </div>
    </div>

    <div class="b-all responsive-table">
      <mat-table [dataSource]="item.items" class="v-middle">

        <!-- Counter Column -->
        <ng-container matColumnDef="counter">
          <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
          <mat-cell *matCellDef="let _item">
            <ng-container *ngIf="assertItemType(_item) as item">
              <span class="header-label">#: </span>
              {{item.counter}}
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Image Column -->
        <ng-container matColumnDef="image">
          <mat-header-cell *matHeaderCellDef>Изображение</mat-header-cell>
          <mat-cell *matCellDef="let _item">
            <ng-container *ngIf="assertItemType(_item) as item">
              <span class="header-label">Изображение: </span>
              <ng-template #tooltipImage>
                <img [src]="getImage(item.product.images[0], 'products', 3)" width="200px" alt="">
              </ng-template>
              <div
                [tooltip]="tooltipImage"
                content-type="template"
                placement="bottom"
                theme="light"
                width="217"
                max-width="217">
                <img [src]="getImage(item.product.images[0], 'products')" alt="" draggable="false"
                     class="table-product-image">
              </div>
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Product Column -->
        <ng-container matColumnDef="product">
          <mat-header-cell *matHeaderCellDef>Товар</mat-header-cell>
          <mat-cell *matCellDef="let _item">
            <ng-container *ngIf="assertItemType(_item) as item">
              <span class="header-label">Товар: </span>
              <a *ngIf="!isMobileApp" [href]="'https://tozon.tj/shop/product/' + item.product.slug"
                 class="custom-item text-primary" target="_blank">
                {{item.product.name}}
              </a>
              <a *ngIf="isMobileApp">
                {{item.product.name}}
              </a>
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Quantity Column -->
        <ng-container matColumnDef="quantity">
          <mat-header-cell *matHeaderCellDef>Количество</mat-header-cell>
          <mat-cell *matCellDef="let _item">
            <ng-container *ngIf="assertItemType(_item) as item">
              <span class="header-label">Количество: </span>
              {{item.quantity}} {{item.product.unit.name}}.
              <ng-template [ngIf]="item.product.convStep && item.product.convUnit">
                <br>
                {{item.product.convStep * item.quantity | number:'1.0-4' | removeComma}}
                {{item.product.convUnit.name}}.
              </ng-template>
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Price Column -->
        <ng-container matColumnDef="price">
          <mat-header-cell *matHeaderCellDef>Цена</mat-header-cell>
          <mat-cell *matCellDef="let _item">
            <ng-container *ngIf="assertItemType(_item) as item">
              <span class="header-label">Цена: </span>
              {{item.price}} c. {{item.price !== item.product.price ? '(' + item.product.price + ' c.)' : ''}}
            </ng-container>
          </mat-cell>
        </ng-container>

        <!-- Total Column -->
        <ng-container matColumnDef="total">
          <mat-header-cell *matHeaderCellDef>Всего</mat-header-cell>
          <mat-cell *matCellDef="let _item">
            <ng-container *ngIf="assertItemType(_item) as item">
              <span class="header-label">Всего: </span>
              {{item.price * item.quantity | number:'1.0-2' | removeComma}} с.
            </ng-container>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <div *ngIf="!item.items || item.items.length === 0">
        <div *ngIf="!item.items || item.items.length === 0" class="table-no-data">
          Нет данных
        </div>
      </div>
    </div>

    <div *ngIf="item.description || item.note" fxLayout="column" fxLayout.gt-md="row" class="m-t-15 m-b-50">
      <div *ngIf="item.description" fxFlex.gt-md="50" fxFlex="100" class="p-5">
        <span class="p-l-5 font-medium">Примечание клиента:</span>
        <div class="b-all p-5 description">
          <div>{{item.description}}</div>
        </div>
      </div>
      <div *ngIf="item.note" fxFlex.gt-md="50" fxFlex="100" class="p-5">
        <span class="p-l-5 font-medium">Примечание от Tozon:</span>
        <div class="b-all p-5 description">
          <div>{{item.note}}</div>
        </div>
      </div>
    </div>

    <div class="text-right m-t-30">
      <h5 class="m-b-5 font-medium">Сумма товаров: {{item.subTotal}} с.</h5>
      <h5 class="m-t-0 m-b-5 font-medium">Доставка: {{item.shippingPrice}} с.</h5>
      <h5 *ngIf="item.tax" class="m-0 m-t-5 font-medium">Налог: {{item.tax}} с.</h5>
      <h3 class="m-b-0 b-t p-t-20">Общая сумма: {{item.total}} с.</h3>
    </div>
  </div>
</mat-dialog-content>
<div [style.box-sizing]="'content-box'" align="end" mat-dialog-actions>
  <button mat-button mat-dialog-close mat-flat-button color="warn">Закрыть</button>
</div>
