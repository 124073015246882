import {ChangeDetectionStrategy, Component, ElementRef, Renderer2} from '@angular/core';
import * as FontFaceObserver from 'fontfaceobserver';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
  ) {
    const materialIcons = new FontFaceObserver('Material Icons');
    materialIcons.load(null, 10000)
      .then(() => this.renderer.addClass(this.elementRef.nativeElement, 'material-icons-loaded'));
  }
}
