export enum Perms {
  userCreate = 'user.create',
  userView = 'user.view',
  userUpdate = 'user.update',
  userDelete = 'user.delete',
  userAll = 'user.*',
  productCreate = 'product.create',
  productView = 'product.view',
  productUpdate = 'product.update',
  productDelete = 'product.delete',
  productAll = 'product.*',
  productImageCreate = 'productImage.create',
  productImageView = 'productImage.view',
  productImageUpdate = 'productImage.update',
  productImageDelete = 'productImage.delete',
  productImageAll = 'productImage.*',
  orderCreate = 'order.create',
  orderView = 'order.view',
  orderUpdate = 'order.update',
  orderDelete = 'order.delete',
  orderAll = 'order.*',
  orderItemCreate = 'orderItem.create',
  orderItemView = 'orderItem.view',
  orderItemUpdate = 'orderItem.update',
  orderItemDelete = 'orderItem.delete',
  orderItemAll = 'orderItem.*',
  productUpdateActive = 'product.updateActive',
  supplierCreate = 'supplier.create',
  supplierView = 'supplier.view',
  supplierUpdate = 'supplier.update',
  supplierDelete = 'supplier.delete',
  supplierAll = 'supplier.*',
  propertyGroupCreate = 'propertyGroup.create',
  propertyGroupView = 'propertyGroup.view',
  propertyGroupUpdate = 'propertyGroup.update',
  propertyGroupDelete = 'propertyGroup.delete',
  propertyGroupAll = 'propertyGroup.*',
  propertyCreate = 'property.create',
  propertyView = 'property.view',
  propertyUpdate = 'property.update',
  propertyDelete = 'property.delete',
  propertyAll = 'property.*',
  manufacturerCreate = 'manufacturer.create',
  manufacturerView = 'manufacturer.view',
  manufacturerUpdate = 'manufacturer.update',
  manufacturerDelete = 'manufacturer.delete',
  manufacturerAll = 'manufacturer.*',
  manufacturerUpdateActive = 'manufacturer.updateActive',
  supplierUpdateActive = 'supplier.updateActive',
  storeCreate = 'store.create',
  storeView = 'store.view',
  storeUpdate = 'store.update',
  storeDelete = 'store.delete',
  storeAll = 'store.*',
  storeUpdateActive = 'store.updateActive',
  pointCreate = 'point.create',
  pointView = 'point.view',
  pointUpdate = 'point.update',
  pointDelete = 'point.delete',
  pointAll = 'point.*',
  pointUpdateActive = 'point.updateActive',
  languageCreate = 'language.create',
  languageView = 'language.view',
  languageUpdate = 'language.update',
  languageDelete = 'language.delete',
  languageAll = 'language.*',
  languageUpdateActive = 'language.updateActive',
  countryCreate = 'country.create',
  countryView = 'country.view',
  countryUpdate = 'country.update',
  countryDelete = 'country.delete',
  countryAll = 'country.*',
  countryUpdateActive = 'country.updateActive',
  regionCreate = 'region.create',
  regionView = 'region.view',
  regionUpdate = 'region.update',
  regionDelete = 'region.delete',
  regionAll = 'region.*',
  regionUpdateActive = 'region.updateActive',
  cityCreate = 'city.create',
  cityView = 'city.view',
  cityUpdate = 'city.update',
  cityDelete = 'city.delete',
  cityAll = 'city.*',
  cityUpdateActive = 'city.updateActive',
  bannerCreate = 'banner.create',
  bannerView = 'banner.view',
  bannerUpdate = 'banner.update',
  bannerDelete = 'banner.delete',
  bannerAll = 'banner.*',
  bannerUpdateActive = 'banner.updateActive',
  slideCreate = 'slide.create',
  slideView = 'slide.view',
  slideUpdate = 'slide.update',
  slideDelete = 'slide.delete',
  slideAll = 'slide.*',
  slideUpdateActive = 'slide.updateActive',
  categoryCreate = 'category.create',
  categoryView = 'category.view',
  categoryUpdate = 'category.update',
  categoryDelete = 'category.delete',
  categoryAll = 'category.*',
  categoryUpdateActive = 'category.updateActive',
  userUpdateActive = 'user.updateActive',
  userChangePassword = 'user.changePassword',
  userAddressCreate = 'userAddress.create',
  userAddressView = 'userAddress.view',
  userAddressUpdate = 'userAddress.update',
  userAddressDelete = 'userAddress.delete',
  userAddressAll = 'userAddress.*',
  permissionCreate = 'permission.create',
  permissionView = 'permission.view',
  permissionUpdate = 'permission.update',
  permissionDelete = 'permission.delete',
  permissionAll = 'permission.*',
  permissionUpdateActive = 'permission.updateActive',
  roleCreate = 'role.create',
  roleView = 'role.view',
  roleUpdate = 'role.update',
  roleDelete = 'role.delete',
  roleAll = 'role.*',
  roleUpdateActive = 'role.updateActive',
  badgeCreate = 'badge.create',
  badgeView = 'badge.view',
  badgeUpdate = 'badge.update',
  badgeDelete = 'badge.delete',
  badgeAll = 'badge.*',
  currencyCreate = 'currency.create',
  currencyView = 'currency.view',
  currencyUpdate = 'currency.update',
  currencyDelete = 'currency.delete',
  currencyAll = 'currency.*',
  currencyUpdateActive = 'currency.updateActive',
  stockStatusCreate = 'stockStatus.create',
  stockStatusView = 'stockStatus.view',
  stockStatusUpdate = 'stockStatus.update',
  stockStatusDelete = 'stockStatus.delete',
  stockStatusAll = 'stockStatus.*',
  unitCreate = 'unit.create',
  unitView = 'unit.view',
  unitUpdate = 'unit.update',
  unitDelete = 'unit.delete',
  unitAll = 'unit.*',
  editorPageCreate = 'editorPage.create',
  editorPageView = 'editorPage.view',
  editorPageUpdate = 'editorPage.update',
  editorPageDelete = 'editorPage.delete',
  editorPageAll = 'editorPage.*',
  userViewAny = 'user.viewAny',
  productViewAny = 'product.viewAny',
  productImageViewAny = 'productImage.viewAny',
  orderViewAny = 'order.viewAny',
  orderItemViewAny = 'orderItem.viewAny',
  supplierViewAny = 'supplier.viewAny',
  propertyGroupViewAny = 'propertyGroup.viewAny',
  propertyViewAny = 'property.viewAny',
  manufacturerViewAny = 'manufacturer.viewAny',
  storeViewAny = 'store.viewAny',
  pointViewAny = 'point.viewAny',
  languageViewAny = 'language.viewAny',
  countryViewAny = 'country.viewAny',
  regionViewAny = 'region.viewAny',
  cityViewAny = 'city.viewAny',
  bannerViewAny = 'banner.viewAny',
  slideViewAny = 'slide.viewAny',
  categoryViewAny = 'category.viewAny',
  userAddressViewAny = 'userAddress.viewAny',
  permissionViewAny = 'permission.viewAny',
  roleViewAny = 'role.viewAny',
  badgeViewAny = 'badge.viewAny',
  currencyViewAny = 'currency.viewAny',
  stockStatusViewAny = 'stockStatus.viewAny',
  unitViewAny = 'unit.viewAny',
  editorPageViewAny = 'editorPage.viewAny',
  currencyRateCreate = 'currencyRate.create',
  currencyRateView = 'currencyRate.view',
  currencyRateUpdate = 'currencyRate.update',
  currencyRateDelete = 'currencyRate.delete',
  currencyRateAll = 'currencyRate.*',
  currencyRateViewAny = 'currencyRate.viewAny',
  categoryUpdateOrder = 'category.updateOrder',
  slideUpdateOrder = 'slide.updateOrder',
  bannerUpdateOrder = 'banner.updateOrder',
  productImageUpdateOrder = 'productImage.updateOrder',
  dashboardCreate = 'dashboard.create',
  dashboardView = 'dashboard.view',
  dashboardUpdate = 'dashboard.update',
  dashboardDelete = 'dashboard.delete',
  dashboardAll = 'dashboard.*',
  orderUpdateStatus = 'order.updateStatus',
  dashboardEmpty = 'dashboard.empty',
}
