import {Notification} from '@onlineShop/models';

export const Notifications: Notification[] = [
  {
    round: 'round-danger',
    icon: 'launch',
    title: 'Добро пожаловать',
    subject: 'Добро пожаловать в Tozon',
    time: 'Сейчас'
  },
  {
    round: 'round-success',
    icon: 'add_circle',
    title: 'Сегодня в Tozon',
    subject: 'Создание склада',
    time: '8 часов назад'
  },
  {
    round: 'round-info',
    icon: 'info',
    title: 'Профиль',
    subject: 'Вы успешно авторизовались',
    time: '1 день назад'
  },
  {
    round: 'round-primary',
    icon: 'person',
    title: 'Наимчон Махмудов',
    subject: 'Сегодня обновился сайт',
    time: '1 месяц назад'
  }
];
