<h2 mat-dialog-title>{{item.title ? item.title : 'Пользователь'}}</h2>
<mat-dialog-content class="mat-typography">
  <div *ngIf="item && item.id" fxLayout="column">
    <div fxFlex="100" class="user">
      <div class="user-image">
        <img [src]="item.image" alt="" draggable="false">
      </div>

      <div fxLayout="column" class="user-info">
        <div class="item"><span class="header-label">Имя: </span>{{item.firstName}}</div>
        <div class="item"><span class="header-label">Фамилия: </span>{{item.lastName}}</div>
        <div class="item"><span class="header-label">E-mail: </span>{{item.email}}</div>
        <div class="item"><span class="header-label">Телефон: </span>{{item.phone}}</div>
        <div class="item"><span class="header-label">Дата рождения: </span>{{item.dateOfBirth}}</div>
        <div class="item m-b-5"><span class="header-label">Пол: </span>
          {{item.gender === 'male' ? 'Мужской' : item.gender === 'female' ? 'Женский' : ''}}
        </div>
        <div class="item m-t-1"><span class="header-label">Создано: </span>
          {{item.createdAt}}
          <mat-icon *ngIf="!item.createdAt" [color]="undefined">remove</mat-icon>
        </div>
        <div class="item"><span class="header-label">Обновлено: </span>
          {{item.updatedAt}}
          <mat-icon *ngIf="!item.updatedAt" [color]="undefined">remove</mat-icon>
        </div>
        <div class="item"><span class="header-label">Код проверен: </span>
          {{item.codeVerifiedAt}}
          <mat-icon *ngIf="!item.codeVerifiedAt" [color]="undefined">remove</mat-icon>
        </div>
        <div [class.m-b-10]="item.type === 'profile'" class="item"><span class="header-label">E-mail проверен: </span>
          {{item.emailVerifiedAt}}
          <mat-icon *ngIf="!item.emailVerifiedAt" [color]="undefined">remove</mat-icon>
        </div>
        <div *ngIf="item.type === 'user'" class="item m-b-10"><span class="header-label">Активность: </span>
          {{item.lastActivity}}
          <mat-icon *ngIf="!item.lastActivity" [color]="undefined">remove</mat-icon>
        </div>
        <div class="item item-align-start"><span class="header-label">Роли: </span>
          <div>
            <div *ngFor="let role of item.roles">{{role.name}}</div>
          </div>
          <mat-icon *ngIf="item.roles?.length === 0" [color]="undefined">remove</mat-icon>
        </div>
        <div *ngIf="item.type === 'user'" class="item"><span class="header-label">Активный: </span>
          <mat-icon [color]="item.active ? 'primary' : undefined">{{item.active ? 'check' : 'remove'}}</mat-icon>
        </div>
      </div>
    </div>

    <mat-divider fxFlex="100" class="address-divider"></mat-divider>

    <div fxFlex="100" class="modal-title">Адреса:</div>

    <div fxFlex="100" class="addresses-list m-b-1">
      <ng-container *ngFor="let address of item.addresses">
        <div class="addresses-list__item address-card">

          <div *ngIf="address.default"
               class="address-card__badge">По умолчанию
          </div>
          <div class="address-card__body">
            <div>
              <div class="address-card__name">{{ item.firstName + ' ' + item.lastName}}</div>
              <div class="font-medium address-card__row">
                {{ address.addressLine1 }}, {{ address.addressLine2 }}<br>
              </div>
              <div class="address-card__row mt-1">
                {{ address.city.name }}, {{ address.region.name }}, {{ address.country.name }}
              </div>
              <div *ngIf="address.postCode" class="address-card__row">
                <div class="address-card__row-title">Почтовый индекс</div>
                <div class="address-card__row-content">{{ address.postCode }}</div>
              </div>
              <div *ngIf="item.phone" class="address-card__row">
                <div class="address-card__row-title">Номер телефона</div>
                <div class="address-card__row-content">{{ item.phone }}</div>
              </div>
              <div *ngIf="item.email" class="address-card__row">
                <div class="address-card__row-title">Адрес электронной почты</div>
                <div class="address-card__row-content">{{ item.email }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="addresses-list__divider"></div>
      </ng-container>
    </div>
  </div>

</mat-dialog-content>
<div [style.box-sizing]="'content-box'"
     [style.justify-content]="item.type === 'profile' ? 'space-between' : 'flex-end'"
     mat-dialog-actions>
  <button *ngIf="item.type === 'profile'" (click)="openDialog()" mat-button color="warn">Удалить профиль</button>
  <button mat-button mat-dialog-close mat-flat-button color="warn">Закрыть</button>
</div>
