import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
// services
import {OrderService} from '@onlineShop/services/order.service';
import {ConfigService} from '@onlineShop/services/config.service';
// models
import {Address, City, Country, Order, OrderItem, OrderStatus, Region, User} from '@onlineShop/models';
// components
import {UserModalComponent} from '@onlineShop/shared/components/user-modal/user-modal.component';
// classes
import {GetRoutes} from '@onlineShop/classes';

interface ItemData extends OrderItem {
  counter: number;
}

interface ItemAddress extends Address {
  country: Country;
  region: Region;
  city: City;
}

interface Item extends Order {
  title: string;
  items: ItemData[];
  address: ItemAddress;
}

@Component({
  selector: 'app-order-modal',
  templateUrl: 'order-modal.component.html',
  styleUrls: ['./order-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderModalComponent {

  isMobileApp = false;

  // get routes
  gr = new GetRoutes();
  // column data table
  displayedColumns: string[] = ['counter', 'image', 'product', 'quantity', 'price', 'total'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public item: Item,
    private _orderService: OrderService,
    private _configService: ConfigService,
    private _dialog: MatDialog,
  ) {
    this.isMobileApp = this._configService.IsMobileApp();

    if (item?.items.length) {
      let counter = 0;
      item.items = item.items.map(i => {
        counter++;
        return {...i, counter};
      });
    }
  }

  openUserModal(user: User): void {
    this._dialog.open(UserModalComponent, {data: {...user, type: 'user'}});
  }

  getOrderStatus(status: number): OrderStatus {
    return this._orderService.getOrderStatus(status);
  }

  assertItemType = (item: ItemData): ItemData => item;

  getImage(image: string, type: string = 'users', size: number = 1): string {
    if (image && image.length) {
      const index = image.lastIndexOf('.');
      if (index !== -1) {
        image = image.substring(0, index) +
          this._configService.imageThumbnail[type][size].name +
          image.substring(index, image.length);
      }
    }
    return image;
  }
}
