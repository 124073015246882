<div [class.label-margin]="label"
     [formGroup]="formGroupEditor"
     class="app-tinymce-editor">
  <label *ngIf="label"
         [class.invalid]="formGroupEditor.get(frCtrlEditorName).invalid &&
                          formGroupEditor.get(frCtrlEditorName).touched"
         class="mat-outline-label">
    {{label}}
    <span *ngIf="isRequired" class="mat-outline-label-required"> *</span>
  </label>
  <tinymce
    [formControlName]="frCtrlEditorName"
    [required]="isRequired"
    [config]="config"
    [loading]="complexContent"
    placeholder="{{label ? label : 'Вставьте текст здесь'}}..."
  ></tinymce>
  <ng-template #complexContent>
    <div class="table-preloader loading"></div>
  </ng-template>
</div>
