<h2 mat-dialog-title>Удаление</h2>
<mat-dialog-content class="mat-typography">
  Вы действительно хотите удалить <b>{{ data.itemName }}</b> без возможности восстановления?
  <mat-form-field *ngIf="data.isProfile" [style.margin-top]="'8px'" appearance="outline">
    <mat-label>Введите пароль для подтверждения</mat-label>
    <input [(ngModel)]="password" type="password" matInput required>
  </mat-form-field>
</mat-dialog-content>
<div [style.box-sizing]="'content-box'" align="end" mat-dialog-actions>
  <button [mat-dialog-close]="data.isProfile ? password : true" [disabled]="disabledBtn" color="warn" mat-button>
    Удалить{{disabledBtn ? ' (' + timer + ')' : ''}}</button>
  <button mat-button mat-dialog-close mat-flat-button>Отменить</button>
</div>
