import {Directive, HostListener} from '@angular/core';
import {NgControl} from '@angular/forms';
// constants
import {NotAlphabetNumber} from '@onlineShop/constants';

@Directive({
  selector: 'input[passwordFormat]'
})
export class PasswordFormatDirective {
  private _el: NgControl;

  constructor(private ngControl: NgControl) {
    this._el = ngControl;
  }

  @HostListener('input', ['$event.target.value']) onInput(value: string): void {
    try {
      const password = value.replace(NotAlphabetNumber, '').toString();
      this._el.control.patchValue(password);
    } catch (error) {
      this._el.control.patchValue(null);
    }
  }
}
