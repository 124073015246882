import {Routes} from '@angular/router';
// guards
import {AuthGuard} from '@onlineShop/guards';
// constants
import {AppRoutes} from '@onlineShop/constants';
// classes
import {GetRoutes} from '@onlineShop/classes';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: GetRoutes.addSlash(AppRoutes.main.dashboard),
    pathMatch: 'full'
  },
  {
    path: AppRoutes.auth.self,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: AppRoutes.main.self,
    loadChildren: () => import('./modules/main/main.module').then(m => m.MainModule),
    canActivate: [AuthGuard]
  },
  {path: '**', redirectTo: '/'}
];
