import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {FormGroup} from '@angular/forms';
// services
import {ConfigService} from '@onlineShop/services/config.service';
// rxjs
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-tinymce-editor',
  templateUrl: './tinymce-editor.component.html',
  styleUrls: ['./tinymce-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TinymceEditorComponent implements OnDestroy {
  @Input() formGroupEditor: FormGroup;
  @Input() frCtrlEditorName: string;
  @Input() isRequired = false;
  @Input() label: string;

  // config TinyMCE
  config: any = {
    height: 500,
    min_height: 500,
    language: 'ru',
    menubar: 'file edit view insert format tools table help',
    plugins: 'print preview paste importcss searchreplace autolink ' +
      'autosave directionality code visualblocks visualchars image ' +
      'link media codesample table charmap hr pagebreak nonbreaking ' +
      'anchor toc insertdatetime advlist lists wordcount imagetools textpattern ' +
      'noneditable help charmap quickbars emoticons',
    toolbar: 'undo redo | ' +
      'bold italic underline strikethrough | ' +
      'fontselect fontsizeselect formatselect | ' +
      'alignleft aligncenter alignright alignjustify | ' +
      'outdent indent |  ' +
      'numlist bullist | ' +
      'forecolor backcolor removeformat | ' +
      'pagebreak | ' +
      'charmap emoticons | ' +
      'preview print | ' +
      'insertfile image media link anchor codesample | ' +
      'ltr rtl',
    toolbar_sticky: true,
    toolbar_mode: 'sliding',
    contextmenu: 'link image imagetools table',
    font_formats: 'Andale Mono=andale mono,times; ' +
      'Arial=arial,helvetica,sans-serif; ' +
      'Arial Black=arial black,avant garde; ' +
      'Book Antiqua=book antiqua,palatino; ' +
      'Comic Sans MS=comic sans ms,sans-serif; ' +
      'Courier New=courier new,courier; ' +
      'Georgia=georgia,palatino; ' +
      'Helvetica=helvetica; ' +
      'Roboto=Roboto,sans-serif; ' +
      'Impact=impact,chicago; ' +
      'Symbol=symbol; ' +
      'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
      'Terminal=terminal,monaco; ' +
      'Times New Roman=times new roman,times; ' +
      'Trebuchet MS=trebuchet ms,geneva; ' +
      'Verdana=verdana,geneva; ' +
      'Webdings=webdings; ' +
      'Wingdings=wingdings,zapf dingbats',
    autosave_ask_before_unload: false,
    autosave_interval: '30s',
    autosave_prefix: '{path}{query}-{id}-',
    autosave_restore_when_empty: false,
    autosave_retention: '2m',
    imagetools_cors_hosts: ['picsum.photos'],
    image_advtab: true,
    image_caption: true,
    importcss_append: true,
    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
    noneditable_noneditable_class: 'mceNonEditable',
    skin: 'oxide',
    content_css: 'default',
    content_style: 'body { font-family:Roboto,Helvetica,Arial,sans-serif; font-size:14px }',
    media_url_resolver: (data, resolve) => {
      data.url = data.url.replace('watch?v=', 'embed/');
      const embedHtml = '<iframe style="border:0;top:0;left:0;width:100%;height:50vw;max-width:650px;max-height:calc(650px * 0.5625)" ' +
        'src="' + data.url + '" scrolling="no" allowfullscreen="allowfullscreen"></iframe>';
      resolve({html: embedHtml});
    },
    file_picker_callback: (callback, value, meta) => {
      if (meta.filetype === 'image') {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = () => {
          const file = input.files[0];

          const reader = new FileReader();
          reader.onload = () => {
            const id = 'blobid' + (new Date()).getTime();
            // @ts-ignore
            const blobCache = tinymce.activeEditor.editorUpload.blobCache;
            // @ts-ignore
            const base64 = reader.result.split(',')[1];
            const blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            callback(blobInfo.blobUri(), {title: file.name, alt: file.name});
          };
          reader.readAsDataURL(file);
        };
        input.click();
      }
    },
    init_instance_callback: () => {
      const freeTiny = document.querySelector('.tox .tox-statusbar__branding a');
      freeTiny['href'] = 'https://tozon.tj';
      freeTiny.setAttribute('aria-label', 'Редактор Tozon');
      freeTiny['innerText'] = 'Редактор Tozon';
    }
  };

  private _destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private _configService: ConfigService,
  ) {
    this._configService.darkMode$
      .pipe(takeUntil(this._destroy$))
      .subscribe(dark => {
        this.config.skin = dark ? 'oxide-dark-mode' : 'oxide';
        this.config.content_css = dark ? 'dark-mode' : 'default';
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
