import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
// angular material
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
// services
import {ToastrService} from 'ngx-toastr';
import {CountryService} from '@onlineShop/services/country.service';
import {RegionService} from '@onlineShop/services/region.service';
import {CityService} from '@onlineShop/services/city.service';
import {AddressService} from '@onlineShop/services/address.service';
// models
import {Address, AddressRequest, City, Country, Region} from '@onlineShop/models';
// functions
import {PostCodeValidation} from '@onlineShop/functions';
// constants
import {InputLength} from '@onlineShop/constants';
// libraries
import {CountryCode} from 'libphonenumber-js';
// rxjs
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs';

interface Item extends Address {
  title: string;
  type: 'add' | 'edit';
  textButton: string;
  addresses: Address[];
}

@Component({
  selector: 'app-address-form-modal',
  templateUrl: 'address-form-modal.component.html',
  styleUrls: ['./address-form-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressFormModalComponent implements OnInit, OnDestroy {
  @ViewChild('closeDialog') closeDialog: ElementRef;

  formGroup: FormGroup;
  errorMessage: string;
  error: string[] = [];
  loading = false;

  // input lengths
  inpLn = InputLength;
  // address
  countries: Country[] = [];
  regions: Region[] = [];
  cities: City[] = [];
  // addresses
  addresses: Address[];

  private _destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    @Inject(MAT_DIALOG_DATA) public item: Item,
    private _fb: FormBuilder,
    private _cdr: ChangeDetectorRef,
    private _toastrService: ToastrService,
    private _countryService: CountryService,
    private _regionService: RegionService,
    private _cityService: CityService,
    private _addressService: AddressService,
  ) {
    if (item && item.addresses && item.addresses.length) {
      this.addresses = item.addresses;
    }
  }

  private static getAddressValue(array: Country[] | Region[] | City[], value: any): string {
    return ((!array || array.length === 0) ? value : value['id']).toString();
  }

  ngOnInit(): void {
    this.formGroup = this._fb.group({
        address: this._fb.group({
          addressLine1: [null, [
            Validators.required,
            Validators.maxLength(this.inpLn.address.addressLine1.maxLength)
          ]],
          addressLine2: [null, [
            Validators.required,
            Validators.maxLength(this.inpLn.address.addressLine2.maxLength)
          ]],
          country: [null, [Validators.required]],
          region: [null, [Validators.required]],
          city: [null, [Validators.required]],
          postCode: [null, [
            Validators.maxLength(this.inpLn.address.postCode.maxLength),
            PostCodeValidation()
          ]],
          default: false
        }),
      },
    );

    if (this.item.id && this.item.type === 'edit') {
      this.setDefaultCountry(this.item.country['countryCode'], true);

      this.formGroup.get('address.addressLine1').setValue(this.item.addressLine1 ? this.item.addressLine1 : null);
      this.formGroup.get('address.addressLine2').setValue(this.item.addressLine2 ? this.item.addressLine2 : null);
      this.formGroup.get('address.postCode').setValidators([
        Validators.maxLength(this.inpLn.address.postCode.maxLength),
        PostCodeValidation(new RegExp(this.item.country['postCodePattern']))
      ]);
      this.formGroup.get('address.postCode').setValue(this.item.postCode ? this.item.postCode : null);
      this.formGroup.get('address.postCode').updateValueAndValidity();
      this.formGroup.get('address.default').setValue(this.item.default ? this.item.default : false);

      this._cdr.markForCheck();
    } else {
      this.setDefaultCountry();
    }
  }

  onSubmitClick(): void {
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid && this.formGroup.enabled) {
      this.loading = true;
      this.formGroup.disable();
      this.errorMessage = null;
      this.error = [];

      // get addresses
      let country = this.formGroup.get('address.country').value;
      let region = this.formGroup.get('address.region').value;
      let city = this.formGroup.get('address.city').value;
      country = AddressFormModalComponent.getAddressValue(this.countries, country);
      region = AddressFormModalComponent.getAddressValue(this.regions, region);
      city = AddressFormModalComponent.getAddressValue(this.cities, city);
      const addressRequest: AddressRequest = {
        country,
        region,
        city,
        addressLine1: this.formGroup.get('address.addressLine1').value,
        addressLine2: this.formGroup.get('address.addressLine2').value,
        postCode: this.formGroup.get('address.postCode').value,
        default: this.formGroup.get('address.default').value
      };

      if (this.item && this.item.id) {
        addressRequest.id = this.item.id;
      }

      if (this.item && this.item.userId) {
        addressRequest.userId = this.item.userId;
      }

      if (this.item.type === 'add') {
        this._addressService.createAddress(addressRequest)
          .subscribe((result: Address[]) => {
              if (result && result.length) {
                this.addresses = result;
                this._cdr.detectChanges();
                this.closeDialog.nativeElement.click();
                this.loading = false;
                this.formGroup.enable();
                this.toastr('Новый адрес пользователя успешно добавлен.', 'success');
              } else {
                this.errorMessage = 'Неизвестная ошибка';
                this.toastr(`Ошибка при добавлении адреса пользователя.`, 'error');
                this.loading = false;
                this.formGroup.enable();
                this._cdr.markForCheck();
              }
            },
            (res: HttpErrorResponse) => {
              if (res && res.message !== null && res.message !== undefined) {
                this.errorMessage = res.message;
                this.error = res.error ? Object.values(res.error) : [];
              } else {
                this.errorMessage = `Ошибка${(res.status ? ` - ${res.status}` : '')}`;
                this.error = [];
              }
              this._cdr.markForCheck();
              this.loading = false;
              this.toastr(`Ошибка при добавлении адреса пользователя.`, 'error');
              this.formGroup.enable();
              this._cdr.markForCheck();
            }
          );
      } else if (this.item.type === 'edit') {
        this._addressService.updateAddress(addressRequest)
          .subscribe((result: Address[]) => {
              if (result && result.length) {
                this.addresses = result;
                this._cdr.detectChanges();
                this.closeDialog.nativeElement.click();
                this.loading = false;
                this.formGroup.enable();
                this.toastr('Адрес пользователя успешно изменён.', 'success');
              } else {
                this.errorMessage = 'Неизвестная ошибка';
                this.toastr(`Ошибка при изменении адреса пользователя.`, 'error');
                this.loading = false;
                this.formGroup.enable();
                this._cdr.markForCheck();
              }
            },
            (res: HttpErrorResponse) => {
              if (res && res.message !== null && res.message !== undefined) {
                this.errorMessage = res.message;
                this.error = res.error ? Object.values(res.error) : [];
              } else {
                this.errorMessage = `Ошибка${(res.status ? ` - ${res.status}` : '')}`;
                this.error = [];
              }
              this._cdr.markForCheck();
              this.loading = false;
              this.toastr(`Ошибка при изменении адреса пользователя.`, 'error');
              this.formGroup.enable();
              this._cdr.markForCheck();
            }
          );
      }
    }
  }

  setCountries(): void {
    if (this.countries.length === 1) {
      this._countryService.read()
        .pipe(takeUntil(this._destroy$))
        .subscribe((result: Country[]) => {
            let country = this.countries[0];
            this.countries = result;
            country = this.countries.find(value => value.countryCode === country.countryCode);
            this.formGroup.get('address.country').setValue(country);
            this._cdr.markForCheck();
          },
          (res: HttpErrorResponse) => {
            if (res && res.message !== null && res.message !== undefined) {
              this.errorMessage = res.message;
              this.error = res.error ? Object.values(res.error) : [];
            } else {
              this.errorMessage = `Ошибка${(res.status ? ` - ${res.status}` : '')}`;
              this.error = [];
            }
            this._cdr.markForCheck();
          }
        );
    }
  }

  changeCountry($event: MatSelectChange = null): void {
    if ($event) {
      const value = $event.value as Country;
      this.formGroup.get('address.postCode').setValidators([
        Validators.maxLength(this.inpLn.address.postCode.maxLength),
        PostCodeValidation(new RegExp(value.postCodePattern))
      ]);
      this.formGroup.get('address.postCode').setValue(null);
      this.formGroup.get('address.postCode').updateValueAndValidity();
      this.setRegions(value.id);
      this._cdr.markForCheck();
    }
  }

  changeRegion($event: MatSelectChange = null): void {
    if ($event) {
      const value = $event.value as Region;
      this.setCities(value.id);
    }
  }

  isArray(obj: any): boolean {
    return Array.isArray(obj);
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  private setDefaultCountry(value: CountryCode = null, setter: boolean = false): void {
    if (value && setter) {
      this._countryService.readByParams({countryCode: value})
        .pipe(takeUntil(this._destroy$))
        .subscribe((res: Country[]) => {
            this.countries = res;
            this._cdr.detectChanges();
            this.formGroup.get('address.postCode').setValidators([
              Validators.maxLength(this.inpLn.address.postCode.maxLength),
              PostCodeValidation(new RegExp(res[0].postCodePattern))
            ]);
            this.formGroup.get('address.country').setValue(res[0]);
            this.formGroup.get('address.postCode').updateValueAndValidity();
            this.setRegions(res[0].id, setter);
            this._cdr.markForCheck();
          },
          (res: HttpErrorResponse) => {
            if (res && res.message !== null && res.message !== undefined) {
              this.errorMessage = res.message;
              this.error = res.error ? Object.values(res.error) : [];
            } else {
              this.errorMessage = `Ошибка${(res.status ? ` - ${res.status}` : '')}`;
              this.error = [];
            }
            this._cdr.markForCheck();
          }
        );
    } else {
      this._countryService.readById('default')
        .pipe(takeUntil(this._destroy$))
        .subscribe((res: Country) => {
            this.countries = [res];
            this._cdr.detectChanges();
            this.formGroup.get('address.postCode').setValidators([
              Validators.maxLength(this.inpLn.address.postCode.maxLength),
              PostCodeValidation(new RegExp(res.postCodePattern))
            ]);
            this.formGroup.get('address.country').setValue(res);
            this.formGroup.get('address.postCode').updateValueAndValidity();
            this.setRegions(res.id);
            this._cdr.markForCheck();
          },
          (res: HttpErrorResponse) => {
            if (res && res.message !== null && res.message !== undefined) {
              this.errorMessage = res.message;
              this.error = res.error ? Object.values(res.error) : [];
            } else {
              this.errorMessage = `Ошибка${(res.status ? ` - ${res.status}` : '')}`;
              this.error = [];
            }
            this._cdr.markForCheck();
          }
        );
    }
  }

  private setRegions(countryId: number, setter: boolean = false): void {
    this._regionService.readByParams({countryId})
      .pipe(takeUntil(this._destroy$))
      .subscribe((result: Region[]) => {
          this.regions = result;
          if (!setter) {
            this.cities = [];
            this.formGroup.get('address.region').setValue(null);
            this.formGroup.get('address.city').setValue(null);
          } else {
            this._cdr.detectChanges();
            const region = this.regions.find(value => value.id === this.item.region['id']);
            this.formGroup.get('address.region').setValue(region || null);
            this.setCities(region.id || null, true);
          }
          this._cdr.markForCheck();
        },
        (res: HttpErrorResponse) => {
          if (res && res.message !== null && res.message !== undefined) {
            this.errorMessage = res.message;
            this.error = res.error ? Object.values(res.error) : [];
          } else {
            this.errorMessage = `Ошибка${(res.status ? ` - ${res.status}` : '')}`;
            this.error = [];
          }
          this._cdr.markForCheck();
        }
      );
  }

  private setCities(regionId: number, setter: boolean = false): void {
    this._cityService.readByParams({regionId})
      .pipe(takeUntil(this._destroy$))
      .subscribe((result: City[]) => {
          this.cities = result;
          if (!setter) {
            this.formGroup.get('address.city').setValue(null);
          } else {
            this._cdr.detectChanges();
            const city = this.cities.find(value => value.id === this.item.city['id']);
            this.formGroup.get('address.city').setValue(city || null);
          }
          this._cdr.markForCheck();
        },
        (res: HttpErrorResponse) => {
          if (res && res.message !== null && res.message !== undefined) {
            this.errorMessage = res.message;
            this.error = res.error ? Object.values(res.error) : [];
          } else {
            this.errorMessage = `Ошибка${(res.status ? ` - ${res.status}` : '')}`;
            this.error = [];
          }
          this._cdr.markForCheck();
        }
      );
  }

  private toastr(message: string, type: 'success' | 'error' | 'info' | 'warning' = 'success'): void {
    const override = {timeOut: 2000, progressBar: true};
    if (type === 'success') {
      this._toastrService.success(message, null, override);
    } else if (type === 'error') {
      this._toastrService.error(message, null, override);
    } else if (type === 'info') {
      this._toastrService.info(message, null, override);
    } else if (type === 'warning') {
      this._toastrService.warning(message, null, override);
    }
  }
}
