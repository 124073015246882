import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Injectable({
  providedIn: 'root'
})
export class AddIconSvgService {
  constructor(
    private _http: HttpClient,
    private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer) {
  }

  add(svg: string, prefix: string, suffix: string): string {
    const res = prefix + suffix;
    this._iconRegistry.addSvgIcon(res, this._sanitizer.bypassSecurityTrustResourceUrl(svg));
    return res;
  }
}
