export const Months: { numeral: number, name: string }[] = [
  {numeral: 1, name: 'Январь'},
  {numeral: 2, name: 'Февраль'},
  {numeral: 3, name: 'Март'},
  {numeral: 4, name: 'Апрель'},
  {numeral: 5, name: 'Май'},
  {numeral: 6, name: 'Июнь'},
  {numeral: 7, name: 'Июль'},
  {numeral: 8, name: 'Август'},
  {numeral: 9, name: 'Сентябрь'},
  {numeral: 10, name: 'Октябрь'},
  {numeral: 11, name: 'Ноябрь'},
  {numeral: 12, name: 'Декабрь'}
];
